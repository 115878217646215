import React from 'react';
import './totp-reset.scss';
import Config from '../../../../config/config';
import CenterDiv from '../../../common/center-div/center-div';
import {useMaskEmail} from '../../../../utils/use-mask-email';

const TotpReset = () => {
  const {maskedEmail} = useMaskEmail(Config.Common.SupportEmailName,Config.Common.SupportEmailDomain);

  return (
    <CenterDiv>
      <div className="totp-reset-component">
        <h1>TOTP Reset</h1>
        <div className="">To reset your TOTP please email us the following. </div>
        <div>Support email: {maskedEmail}</div>
        <div className="items">
          <div className="item">Any IP Addresses you've used on this account</div>
          <div className="item">Any Device you've used on this account</div>
          <div className="item">Regions and Countries you've used on this account</div>
        </div>
        <div className="faint">Please wait 3-7 days for us to investigate this.</div>
        <div className="faint">We may ask you to provide more information if the above information is not sufficient</div>
      </div>
    </CenterDiv>
    
  );

};

export default TotpReset;