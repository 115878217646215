import React,{useEffect, useState} from 'react';
import './subscribed.scss';
import AccountStatusEnum,{AccountStatusIdEnum} from '../../../enums/account-status';
import {useMaskEmail} from '../../../utils/use-mask-email';
import Config from '../../../config/config';
import { Link } from 'react-router-dom';
import useScript from '../../../utils/use-script';

const Subscribed = ({statusArg}) => {

  let confettiLink = "https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js";

  const {maskedEmail} = useMaskEmail(Config.Common.SupportEmailName,Config.Common.SupportEmailDomain);
  const [loaded,error] = useScript(confettiLink); 

  let newStatus = AccountStatusIdEnum[AccountStatusEnum.Premium];
  if(statusArg in AccountStatusIdEnum){
    newStatus = AccountStatusIdEnum[statusArg];
  }

  const triggerConfetti = () => {
    window.confetti({
      particleCount: 200,
      spread: 85,
      origin: {y: .6},
    })
  };

  useEffect(() => {
    if(loaded){
      triggerConfetti();
    }
  },[loaded]);

  return (
    <div className="subscribed-comp">
      <h1>Account Upgraded!</h1>
      <p>Success! Your account has been upgraded to <span className='bold'>{newStatus} Status</span>. </p>
      <p>If you have any issues with your account please open a ticket via the <Link to="/support">Support Page</Link>. </p>
      <p>Or send us an email at {maskedEmail}. </p>
      <p>Thank you for your purchase. </p>
    </div>
  );

};

export default Subscribed;