import React,{useState,useEffect} from 'react';
import './reset-password.scss';

import Config from '../../../../config/config';
import {request,unSubRequest} from '../../../../utils/request';
import CenterDiv from '../../../common/center-div/center-div';
import PasswordInput from '../../../elements/password-input/password-input';
import Button from '../../../elements/button/button';
import Loading from '../../../common/loading/loading';
import {toast} from 'react-toastify';
import { Redirect } from 'react-router';
import Recaptcha from 'react-google-recaptcha';
import * as NumberUtil from '../../../../utils/number';
 
// Mostly the same as verify-email - separate to keep me sane
const ResetPassword = ({tokenArg}) => {

  const [email,setEmail] = useState("");
  const [recaptchaKey,setRecaptchaKey] = useState(1);
  const [recaptchaValue,setRecaptchaValue] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [verifyingToken,setVerifyingToken] = useState(false);
  const [verifiedToken,setVerifiedToken] = useState(false);
  const [token] = useState(tokenArg);
  const [redirect,setRedirect] = useState(false);

  useEffect(() => {
    return () => {
      unSubRequest("reset-password-get");
      unSubRequest("reset-password-request");
      unSubRequest("reset-password-patch");
    }
  },[]);

  useEffect(() => {
    if(token !== undefined && token !== null && token !== ""){
      const endpoint = "/reset-password/"+token;
      setVerifyingToken(true);
      request("reset-password-get",endpoint,"GET", {}, {
        then: function(res){ setVerifiedToken(true); },
        catch: function(err){ toast.error(err.message); },
        finally: function(){ setVerifyingToken(false); }
      });
    }
  },[token]);

  const handleRequestEmailSubmit = (e) => {
    e.preventDefault();
    if(email === "") return false;

    const data = { email, recaptchaValue, };
    setLoading(true);
    request("reset-password-request","/reset-password-request","POST", data, {
      then: function(res){ 
        toast.success("Reset Password Email sent! Please check your email. "); 
      },
      catch: function(err){ toast.error(err.message); },
      finally: function(){ 
        let randInt = NumberUtil.getRandomInt(2,99999999);
        setRecaptchaKey(randInt);
        setRecaptchaValue("");
        setLoading(false); 
      }
    });
  }

  const handleSetNewPassword = (e) => {
    e.preventDefault();
    if(newPassword === "") return false;
    
    const data = { password: newPassword, token: token,};
    if(localStorage.getItem("device")) data.deviceToken = localStorage.device;

    setLoading(true);
    request("reset-password-patch","/reset-password","PATCH", data, {
      then: function(res){
        toast.success("Successfully Set New Password!");
        setRedirect(true);
      },
      catch: function(err){ toast.error(err.message); },
      finally: function(){ setLoading(false); }
    });
  }

  if(redirect){
    return <Redirect to="/my-account" />
  }

  if(verifyingToken){
    return(
      <CenterDiv>
        <div className="reset-password">
          <h1>Verifying Token </h1>
          <Loading show={true} size={"48px"}/>
        </div>
      </CenterDiv>
    )
  }

  if(verifiedToken){
    return(
      <CenterDiv>
        <div className="verify-email">
          <h1>Set New Password</h1>
          <form onSubmit={handleSetNewPassword}>
            <label>New Password</label>
            <PasswordInput disabled={loading} value={newPassword} placeholder="New Password" onChange={(e) => {setNewPassword(e.target.value)}}/>
            <Button disabled={loading} type="submit" status={(loading)?"loading":""} value="Reset Password" />
          </form>
        </div>
      </CenterDiv>
    )
  }

  return (
    <CenterDiv>
      <div className="reset-password">
        <h1>Reset Password</h1>
        <p>Forgot your password? Type in your email below to receive a reset link.</p>
        <form onSubmit={handleRequestEmailSubmit}>
          <label>Email</label>
          <input disabled={loading} type="email" placeholder="Email" value={email} onChange={(e) => {setEmail(e.target.value)}} />
          <Recaptcha key={recaptchaKey}
            className="recaptcha" sitekey={Config.Keys.RecaptchaPubKey} 
            onChange={(value) => {setRecaptchaValue(value)}}
            onExpired={() => {setRecaptchaValue("")}}/>
          <Button disabled={loading} type="submit" status={(loading)?"loading":""} value="Reset Password" />
        </form>
      </div>
    </CenterDiv>
  );
};

export default ResetPassword;