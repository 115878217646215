import React, { useState, useEffect } from 'react';
import './change-email.scss';
import CenterDiv from '../../../common/center-div/center-div';
import Loading from '../../../common/loading/loading';
import {request,unSubRequest} from '../../../../utils/request';
import { Redirect } from 'react-router';
import useUserState from '../../../../store/user';
import {clone} from '../../../../utils/clone';
import {toast} from 'react-toastify';

const ChangeEmail = ({tokenArg}) => {

  const {user,setUser,} = useUserState();
  const [verifyingToken,setVerifyingToken] = useState(false);
  const [verifiedToken,setVerifiedToken] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");
  const [token,setToken] = useState(tokenArg);

  useEffect(() => {
    return () => {
      unSubRequest("change-email");
    }
  },[])

  useEffect(() => {
    if(token !== undefined && token !== null && token !== ""){
      const endpoint = "/change-email/"+token;
      setVerifyingToken(true);
      request("change-email",endpoint,"GET", {}, {
        then: function(res){
          if(res.data === undefined || res.data.res === undefined || res.data.res.newEmail === undefined){
            alert("error settings new email to user, please relogin to see changes");
          }else{
            let newEmail = res.data.res.newEmail;
            let userClone = clone(user);
            userClone.email = newEmail;
            setUser(userClone);
          }
          setVerifiedToken(true);
          setToken("");
        },
        catch: function(err){
          setErrorMessage(err.message);
        },
        finally: function(){
          setVerifyingToken(false);
        }
      });
    }
  },[token,user,setUser]);

  if(verifyingToken){
    return(
      <CenterDiv>
        <div className="change-email">
          <h1>Verifying Token</h1>
          <Loading show={true} size={"48px"}/>
        </div>
      </CenterDiv>
    )
  }

  if(verifiedToken){
    toast.success("Success! Email Changed. toast, Redirecting you")
    return (
      <Redirect to="/my-account"/>
    )
  }

  return (
    <CenterDiv>
      <div className="change-email">
        <h1>Verify Email Change</h1>
        <ul className="errors">
          {(errorMessage !== "")?<li>{errorMessage}</li>:<li>Could not read that token</li>}
        </ul>
        <p>Looks like something went wrong. Please request a new one. </p>
        <ul>
          <li>1. If you're not signed in already, sign in. </li>
          <li>2. Go to My account. </li>
          <li>3. Click on the edit button next to email. </li>
          <li>4. Type in the email you'd like to change your account to. </li>
        </ul>
      </div>
    </CenterDiv>
  );
};

export default ChangeEmail;