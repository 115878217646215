const appName = "Fjorney";
const leadingTitle = "";
const trailingTitle = " | " + appName;

const Seo = {
  Home: {
    Title: "Midjourney API - Midjourney Auto - Midjourney Bot",
    Desc: "Unlock seamless journeys with Fjorney! Explore the power of Midjourney API, streamline tasks with Midjourney Auto, and elevate efficiency with Midjourney Bot",
    Keywords: "Midjourney bot, Midjourney auto, Midjourney API",
  },
  Dash: {
    Title: leadingTitle+"Dashboard" + trailingTitle,
    Desc: "Access your Fjorney dashboard to navigate through Midjourney Bot functionalities, prompt generation, and API support.",
    Keywords: "Fjorney Dashboard, Midjourney Bot Guide, Prompt Help, API Support",
  },
  HowToUse: {
    Title: leadingTitle+"How To Use Midjourney Bot" + trailingTitle,
    Desc: "Learn how to use Midjourney Bot effectively with Fjorney. Get detailed guidance on prompt creation, API usage, and more.",
    Keywords: "Midjourney Bot Usage, Midjourney Prompt Guide, API Instructions, Fjorney Tutorials",
  },
  Pricing: {
    Title: "Midjourney API Pricing - Midjourney Subscription Plans - Midjourney Pricing Plans",
    Desc: "Explore Midjourney API Pricing and Subscription Plans! Discover various Midjourney Pricing Plans tailored to fit your needs and budget. Get started with Midjourney today!",
    Keywords: "Fjorney Pricing, Midjourney Bot Subscription, Affordable API Access, Prompt Generator Plans",
  },
  Help: {
    Title: leadingTitle+"Help and Support" + trailingTitle,
    Desc: "Need assistance with Fjorney or Midjourney Bot? Explore our help section for prompt support, API guidance, and more.",
    Keywords: "Fjorney Help, Midjourney Bot Support, Prompt Assistance, API Help",
  },
  Login: {
    Title: leadingTitle+"Login" + trailingTitle,
    Desc: appName + " Login. Access your account to explore Midjourney Bot features, prompt generation, and more.",
    Keywords: appName + " Login, User Access, Account Management",
  },
  CreateAccount: {
    Title: leadingTitle+"Create Account" + trailingTitle,
    Desc: appName + " Create Account. Join Fjorney to unlock the full potential of Midjourney Bot with user-friendly tools and resources.",
    Keywords: appName + " Create Account, Register, User Signup",
  },
  MyAccount: {
    Title: leadingTitle+"My Account" + trailingTitle,
    Desc: appName + " My Account. Manage your profile, preferences, and access personalized Midjourney Bot features.",
    Keywords: appName + " My Account, User Profile, Account Settings",
  },
  ResetPassword: {
    Title: leadingTitle+"Reset Password" + trailingTitle,
    Desc: appName + " Reset Password. Forgot your password? No worries, we got you covered. Easily reset and recover your account access.",
    Keywords: appName + " Reset Password, Password Recovery, Account Security",
  },
  VerifyEmail: {
    Title: leadingTitle+"Verify Email" + trailingTitle,
    Desc: appName + " Verify Email. Need to verify your email? Here's where you want to be. Secure your account and stay updated.",
    Keywords: appName + " Verify Email, Email Confirmation, Account Security",
  },
  TermsOfUse: {
    Title: leadingTitle+"Terms Of Use" + trailingTitle,
    Desc: appName + " Terms Of Use. Understand the guidelines and rules for using Fjorney and Midjourney Bot services.",
    Keywords: appName + " Terms Of Use, User Agreement, Legal Guidelines",
  },
  PrivacyPolicy: {
    Title: leadingTitle+"Privacy Policy" + trailingTitle,
    Desc: appName + " Privacy Policy. Your privacy matters. Learn how we handle and protect your personal data.",
    Keywords: appName + " Privacy Policy, Data Protection, User Privacy",
  },
  Feedback: {
    Title: leadingTitle+"Feedback" + trailingTitle,
    Desc: "Share your Feedback with " + appName + ". Your opinions and suggestions help us improve our Midjourney Bot services.",
    Keywords: appName + " Feedback, User Suggestions, Service Improvement",
  },
  Support: {
    Title: leadingTitle+"Support" + trailingTitle,
    Desc: "Get Support at " + appName + ". Find answers to your queries and technical assistance for Midjourney Bot.",
    Keywords: appName + " Support, Technical Help, Customer Service",
  },
  Welcome: {
    Title: leadingTitle+"Welcome" + trailingTitle,
    Desc: "Welcome to " + appName + ". Discover the world of Midjourney Bot and how Fjorney can enhance your creative journey.",
    Keywords: "Welcome to Fjorney, Midjourney Bot Introduction, Getting Started",
  },
};

export default Seo;
