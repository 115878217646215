const SocketEventEnum = {
	Subscribe: "subscribe",
	Unsubscribe: "unsubscribe",
	Message: "message",
	Broadcast: "broadcast",
	KeepAlive: "keep-alive",
	UserLogin: "user-login",
	UserLogout: "user-logout",
	OpenSocket: "open-socket",
	CloseSocket: "close-socket",
}

export default SocketEventEnum;