import { useEffect, useState } from 'react';

const useScript = (src) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        // Check if the script is already present in the document
        let script = document.querySelector(`script[src="${src}"]`);
        if (!script) {
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            script.async = true;
            document.body.appendChild(script);

            const onLoad = () => setLoaded(true);
            const onError = () => {
                setError(true);
                script.remove();
            };

            script.addEventListener('load', onLoad);
            script.addEventListener('error', onError);

            // Clean-up function
            return () => {
                script.removeEventListener('load', onLoad);
                script.removeEventListener('error', onError);
            };
        } else {
            // The script is already on the page
            setLoaded(true);
        }
    }, [src]);

    return [loaded, error];
};

export default useScript;
