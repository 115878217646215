const SocketStateEnum = {
	Connecting: 0,
	Open: 1,
	Closing: 2,
	Closed: 3,
}
export const SocketStateArr = [
  "Connecting",
	"Open",
	"Closing",
	"Closed",
];

export default SocketStateEnum;