
import {Environment,EnvironmentEnum, SandboxEnvArr} from './env';
import {SnsTypeTextEnum} from '../enums/sns-type';

export const ConfigVariables = {
  TestMode: false,
  // SnsAuthMethods: [SnsTypeTextEnum.Google,SnsTypeTextEnum.Facebook],
  SnsAuthMethods: [SnsTypeTextEnum.Google],
}

const Config = {
  Common:{
    ApiBaseUrl: "http://localhost:3085",
    ApiBaseSocket: "ws://localhost:3085",
    ApiSandboxBaseUrl: "http://localhost:3086",
    ApiSandboxBaseSocket: "ws://localhost:3086",
    AppName: "Fjorney",
    Company: "Fjorney",
    CdnUrl: "https://cdn.fjorney.com",
    CompanyLink: "https://fjorney.com",
    FrontEndUrl: "http://localhost:3080",
    LiveFrontEndUrl: "http://localhost:3080",
    SandboxFrontEndUrl: "http://localhost:3081",
    ApiDocsUrl: "http://localhost:3082",
    SupportEmailName: "mail",
    SupportEmailDomain: "fjorney.com",
    PaypalBaseUrl: "https://www.paypal.com",
  },
  Keys:{
    PaypalPublicKey: "AQ8TkE2g9bVt4sV--Pc4Vo1bEqGuej_sRez6pKPzJOO1jsJzQwooLBxv9L0G9sDv8sdclR_w6_AFixd8",
    DawipayStoreUid: "store_7un2wmSS6kJd1PphYb8i8R",
    StripePublicKey: "pk_test_51OF4UTJliGjV36OoID3LIurlQQUq1ONgaaJwRDKXB3kuxKXyN4oFwumJnebjA9b0RD2s90XKsNYd9rxaEPgEccFc00EnOX3gOC",
    GoogleAnalytics: "",
    // RecaptchaPubKey: "6Lc7B1woAAAAAJsfY89SLsURB0EOPXoMBKJPWtpo",
    // RecaptchaPubKey: "6Lc0NiEpAAAAACijQx9Yo53SqdSf-1B29563iRBE",//v3
    RecaptchaPubKey: "6LecoyQpAAAAAKEU_EZGf4hcpSssn53kHxxbO8S6",
  },
  PriceUids:{
    Stripe:{
      Premium:{
        Month1:"price_1OF5VAJliGjV36OoPRNphHOs",
        Year1:"price_1OF6wWJliGjV36Oob85wmyUc",
      },
      Pro:{
        Month1:"price_1OF5VnJliGjV36OoGGjiP3C9",
        Year1:"price_1OF6xJJliGjV36OoZ773CKIi",
      },
    },
    Paypal:{
      Premium:{
        Month1:"P-41N754117L2370528L6I44FY",
        Year1:"P-41N754117L2370528L6I44FY",
      },
      Pro:{
        Month1:"P-41N754117L2370528L6I44FY",
        Year1:"P-41N754117L2370528L6I44FY",
      },
    },
    Dawipay:{
      Premium:{
        Month1:"price_tbv14RRJA1GBTjiziTCo8C",
        Year1:"price_ndHMWsA92RAG6kipjSDF3i",
      },
      Pro:{
        Month1:"price_1qPohe2T9isMGxE6RQrSV9",
        Year1:"price_krPxbGYvBMMiTNk1UKKS2D",
      },
    }
  },
  Sandbox: false,
};

if(SandboxEnvArr.indexOf(Environment) !== -1){
  Config.Sandbox = true;
}
if(Environment === EnvironmentEnum.Local2){
  console.log("Running Local2 Env");
  Config.Common.ApiBaseUrl = "http://localhost:3086";
  Config.Common.ApiBaseSocket = "ws://localhost:3086";
  Config.Common.FrontEndUrl = "http://localhost:3088";
  Config.Common.LiveFrontEndUrl = "http://localhost:3088";
}
if(Environment === EnvironmentEnum.Local3){
  console.log("Running Local3 Env");
  Config.Common.ApiBaseUrl = "http://localhost:3087";
  Config.Common.ApiBaseSocket = "ws://localhost:3087";
  Config.Common.FrontEndUrl = "http://localhost:3089";
  Config.Common.LiveFrontEndUrl = "http://localhost:3089";
}

if(Environment === EnvironmentEnum.LocalSandbox){
  Config.Common.ApiBaseUrl = Config.Common.ApiSandboxBaseUrl;
  Config.Common.ApiBaseSocket = Config.Common.ApiSandboxBaseSocket;
  Config.Common.FrontEndUrl = Config.Common.SandboxFrontEndUrl;
}


if(Environment === EnvironmentEnum.Release || Environment === EnvironmentEnum.ReleaseSandbox){
  Config.Common.ApiBaseUrl = "https://release-api.fjorney.com";
  Config.Common.FrontEndUrl = "https://release.fjorney.com";
  Config.Common.LiveFrontEndUrl = "https://release.fjorney.com";
  Config.Common.ApiDocsUrl = "https://release-docs.fjorney.com";
  Config.Common.ApiBaseSocket = "wss://release-api.fjorney.com";
}

if(Environment === EnvironmentEnum.Production || Environment === EnvironmentEnum.ProductionSandbox){
  Config.Common.ApiBaseUrl = "https://api.fjorney.com";
  Config.Common.FrontEndUrl = "https://fjorney.com";
  Config.Common.LiveFrontEndUrl = "https://fjorney.com";
  Config.Common.ApiDocsUrl = "https://docs.fjorney.com";
  Config.Common.ApiBaseSocket = "wss://api.fjorney.com";
  Config.Common.PaypalBaseUrl = "https://www.paypal.com";
}

if(Environment === EnvironmentEnum.Production || Environment === EnvironmentEnum.Release){
  Config.Keys.StripePublicKey = "pk_live_51OF4UTJliGjV36OoWNoT2vFNmXCqJ3DX8DScTI8gGC51XoucOpYibouabFmGkgQxE0glu8DIc0llGzuRDEFZd8d300ZN1WKyGl";
  Config.Keys.PaypalPublicKey = "AVWpxpAupoZv0n-N7F6mSWhPfz25f7eHVcK7bcOfB-uBAf4ZdrD02qeML-UOxjZMkcb9tGRpi2pqvzSJ";
  Config.Keys.DawipayStoreUid = "store_oM85WhQDWkBcnTnCPh6xew";

  Config.PriceUids.Stripe.Premium.Month1 = "price_1OF6y6JliGjV36OoZG0y2f34";
  Config.PriceUids.Stripe.Premium.Year1 = "price_1OF6y6JliGjV36OoTXazc6O0";
  Config.PriceUids.Stripe.Pro.Month1 = "price_1OF6xzJliGjV36OoUGpiFmvr";
  Config.PriceUids.Stripe.Pro.Year1 = "price_1OF6xzJliGjV36OoTyhmHWad";
  Config.PriceUids.Paypal.Premium.Month1 = "P-9LR284950L702420YMVT7CJA";
  Config.PriceUids.Paypal.Premium.Year1 = "P-54G16918SH198911RMVT7CTA";
  Config.PriceUids.Paypal.Pro.Month1 = "P-1FV76432LY021812CMVT7BSI";
  Config.PriceUids.Paypal.Pro.Year1 = "P-1W331948KH708181FMVT7B7A";
  Config.PriceUids.Dawipay.Premium.Month1 = "price_2v1KZnFuDg8rQgrdG4scYh";
  Config.PriceUids.Dawipay.Premium.Year1 = "price_vXcby27mBMP74pf5p3JrpR";
  Config.PriceUids.Dawipay.Pro.Month1 = "price_sYtUyKRvKf4toJSr9MqRGg";
  Config.PriceUids.Dawipay.Pro.Year1 = "price_j2ASmwZy9ea48V7BJ8tr4o";

}

export default Config;
