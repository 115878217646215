
import React from 'react';
import './css/styles/app.scss';
import './css/styles/common.scss';
import './css/styles/toastify.scss';

import { Router, Route, Switch, } from 'react-router-dom';
import history from './store/history';

import Careers from './components/views/about/careers';
import Footer from './components/footer/footer';
import Navi from './components/navi/navi';
import EntryLogin from './components/views/entry/entry-login';
import EntryCreateAccount from './components/views/entry/entry-create-account';
import Totp from './components/views/entry/totp/totp';
import TotpReset from './components/views/entry/totp/totp-reset';
import NewDevice from './components/views/entry/new-device/new-device';
import SnsAuth from './components/views/entry/sns-auth/sns-auth';
import Home from './components/views/home/home';
import NotFound from './components/views/not-found/not-found';
import MyAccount from './components/views/my-account/my-account';
import Subscribed from './components/views/subscribed/subscribed';
import PrivacyPolicy from './components/views/about/privacy-policy/privacy-policy';
import TermsOfUse from './components/views/about/terms-of-use/terms-of-use';
import Test from './components/views/test/test';
import TestSocket from './components/views/test-socket/test-socket';

import BlockedCountry from './components/views/blocked-country/blocked-country';
import Support from './components/views/support/support';
import Help from './components/views/support/help';
import BugReport from './components/views/support/bug-report';
import Feedback from './components/views/support/feedback';
import Ticket from './components/views/support/ticket';

import ResetPassword from './components/views/email-related/reset-password/reset-password';
import VerifyEmail from './components/views/email-related/verify-email/verify-email';
import ChangeEmail from './components/views/email-related/change-email/change-email';

import LoginRoute from './components/common/routes/login-route';
import AuthRoute from './components/common/routes/auth-route';
import NormRoute from './components/common/routes/norm-route';
import ScrollToTop from './components/common/routes/scroll-to-top';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip, } from 'react-toastify';

import AccountStatusEnum from './enums/account-status';
import SnsTypeEnum from './enums/sns-type';

import Settings from './config/settings';
import packageJson from '../package.json';
import {Environment,SandboxEnvArr} from './config/env';
import Base64 from './components/views/base64/base64';
import HowToUse from './components/views/how-to-use/how-to-use';
import Pricing from './components/views/pricing/pricing';
import Blog from './components/views/blog/blog';
import Dashboard from './components/views/dashboard/dashboard';
import Welcome from './components/views/welcome/welcome';
import Uninstall from './components/views/uninstall/uninstall';



console.log("App Version: ",packageJson.version);
console.log("React Version: ",React.version);
console.log("Environment: ",Environment);


const App = () => {

  const subDirectory = "/";
  const naviClass = (Settings.TopNavi)?" top-navi":" side-navi";
  const stickyNaviClass = (Settings.StickyNavi)?" sticky-navi":""; 
  const sandboxClass = (SandboxEnvArr.indexOf(Environment) !== -1)?" sandbox":"";

  const FooterDiv = () => {
    if(!Settings.Footer) return null;
    return (
      <Footer />
    )
  }

  return (
    <Router basename={subDirectory} history={history}>
      <ScrollToTop />
      <div className={"app"+naviClass+stickyNaviClass+sandboxClass}>
        <Navi />
        <div id="content-container">
          <div id="content">
            <Switch>
              <NormRoute exact path="/" component={(props) => <Home history={props.history} />} />  
              <LoginRoute path="/login(.*)" component={() => {return <EntryLogin />} } />
              <LoginRoute path="/create-account(.*)" component={() => {return <EntryCreateAccount />} } />
              <LoginRoute path="/create-account/:code" component={(props) => <EntryCreateAccount code={props.match.params.code}/>} />
              <LoginRoute path="/r/:code" component={(props) => <EntryCreateAccount code={props.match.params.code}/>} />


              <AuthRoute path="/dashboard" component={Dashboard} />
              <NormRoute path="/how-to-use" component={HowToUse} />

              <NormRoute path="/midjourney-api-pricing" component={Pricing} />
              {/* <NormRoute path="/pricing" component={Pricing} /> */}
              <NormRoute path="/blog" component={Blog} />

              <LoginRoute path="/totp" component={Totp} />
              <LoginRoute path="/new-device" component={NewDevice} />
              <NormRoute path="/totp-reset" component={TotpReset} />

              <AuthRoute path="/my-account" minStatus={AccountStatusEnum.Basic} component={MyAccount} />
              <AuthRoute path="/subscribed/:status" minStatus={AccountStatusEnum.Premium} component={(props) => <Subscribed statusArg={props.match.params.status} />} /> 

              <LoginRoute exact path="/verify-email" component={VerifyEmail} /> 
              <LoginRoute path="/verify-email/:token" component={(props) => <VerifyEmail tokenArg={props.match.params.token}/>} /> 
              <NormRoute exact path="/reset-password" component={ResetPassword} /> 
              <NormRoute path="/reset-password/:token" component={(props) => <ResetPassword tokenArg={props.match.params.token} />} /> 
              <NormRoute exact path="/verify-change-email" component={ChangeEmail} />
              <NormRoute path="/verify-change-email/:token" component={(props) => <ChangeEmail tokenArg={props.match.params.token} />} /> 

              <LoginRoute path="/google-auth" component={() => <SnsAuth snsType={SnsTypeEnum.Google}/>} />
              <LoginRoute path="/facebook-auth" component={() => <SnsAuth snsType={SnsTypeEnum.Facebook}/>} />

              <NormRoute path="/careers" component={Careers} />
              <NormRoute path="/privacy-policy" component={PrivacyPolicy} />
              <NormRoute path="/terms-of-use" component={TermsOfUse} />

              <NormRoute path="/unsupported-country" component={BlockedCountry} />
              <NormRoute path="/help" component={Help} />
              <NormRoute path="/contact" component={Support} />
              <NormRoute path="/support" component={Support} />
              <NormRoute path="/feedback" component={Feedback} />
              <NormRoute path="/bug-report" component={BugReport} />
              <AuthRoute path="/ticket/:uid" component={(props) => <Ticket uid={props.match.params.uid}/>} />

              <NormRoute path="/welcome" component={Welcome} />
              <NormRoute path="/uninstall" component={Uninstall} />

              <NormRoute path="/base64" component={Base64} />
              <NormRoute path="/test-socket" component={TestSocket} />
              <NormRoute path="/test" component={Test} />
              <AuthRoute path="/test-auth" minStatus={AccountStatusEnum.Basic} component={Test} />

              <Route component={NotFound} />  
            </Switch>
          </div>
          <FooterDiv/>
        </div>

        <ToastContainer 
          position="top-right"
          autoClose={4000}
          transition={Flip}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
      </div>
    </Router> 
  );
}

export default App;
