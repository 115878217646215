import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM

import './paypal.scss';
import Config from '../../../config/config';
import useScript from '../../../utils/use-script';
import { request,unSubRequest } from '../../../utils/request';
import {toast} from 'react-toastify';
import Loading from '../../../components/common/loading/loading';

const Paypal = ({priceUid,callback}) => {

  const [loading,setLoading] = useState(false);
  const paypalScriptUrl = Config.Common.PaypalBaseUrl+"/sdk/js?client-id="+Config.Keys.PaypalPublicKey+"&vault=true&intent=subscription";
  const [loaded, error] = useScript(paypalScriptUrl);

  useEffect(() => {
    return () => {
      unSubRequest("paypal-save-sub-id");
    }
  },[])

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      'plan_id': priceUid // Replace with your plan ID
    });
  };

  const onApprove = (data, actions) => {
    // console.log("data",data);
    passSubIdToBackend(data.subscriptionID);
  };

  const passSubIdToBackend = (id,) => {
    let body = { subId: id, }
    setLoading(true);
    request("paypal-save-sub-id","/paypal-save-sub-id","POST", body, {
      then: function(res){
        console.log("res",res.data.res);
        setLoading(false);
        if(callback){
          callback(true);
        }
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  if (error) { 
    return <div className='paypayl-comp'>Failed to load PayPal SDK script.</div>; 
  }
  if (!loaded) { 
    return <div className='paypayl-comp'>Loading...</div>;  
  }

  const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

  return (
    <div className="paypal-comp">
      <Loading show={loading}/>
      <PayPalButton
        createSubscription={(data, actions) => createSubscription(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
      />
    </div>
  );

};

export default Paypal;
