import React from 'react';
import Entry from './entry';
import './entry.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

const EntryLogin = () => {

  return (
    <div className="entry-login">

      <Helmet>
        <title>{Seo.Login.Title}</title>
        <meta name="description" content={Seo.Login.Desc} />
        <meta name="keywords" content={Seo.Login.Keywords} />
      </Helmet>

      <Entry option="login"/>
    </div>
  )
};

export default EntryLogin;