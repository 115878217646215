import React, { useState,useEffect } from 'react';
import './support.scss';
import Config from '../../../config/config';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import {Link} from 'react-router-dom';
import useUserState from '../../../store/user';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Loading from '../../common/loading/loading';
import * as StringUtil from '../../../utils/string';
import * as DateUtil from '../../../utils/date';
import {useMaskEmail} from '../../../utils/use-mask-email';
import TextareaAutosize from 'react-textarea-autosize';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

const Support = () => {

  const [loadingF,setLoadingF] = useState(false);
  const [loadingS,setLoadingS] = useState(false);
  const {user} = useUserState();
  const {maskedEmail} = useMaskEmail(Config.Common.SupportEmailName,Config.Common.SupportEmailDomain);

  const options = [
    {value: "general",display:"General Question"},
    {value: "api",display:"API"},
  ]

  const [selectValue,setSelectValue] = useState(options[0].value);
  const [textValue,setTextValue] = useState("");
  const [show,setShow] = useState(false);
  const [tickets,setTickets] = useState([]);

  // useEffect(() => {
  //   console.log("tickets",tickets);
  // },[tickets])

  useEffect(() => {
    fetchTickets();
    return () => {
      unSubRequest("tickets");
      unSubRequest("open-ticket");
    }
  },[]);

  const handleSubmit = (e) => {
    e.preventDefault();
    send();
  } 

  const fetchTickets = () => {
    setLoadingF(true);
    request("tickets","/tickets","GET", {}, {
      then: function(res){ 
        setTickets(res.data.res.tickets);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingF(false);}
    });
  }

  const send = () => {
    if(textValue === "") return;
    
    let data = {
      text: textValue,
      type: selectValue,
    }
    setLoadingS(true);
    request("open-ticket","/open-ticket","POST", data, {
      then: function(res){ 
        toast.success("Ticket Opened! We'll respond as soon as possible. ")
        setTextValue("");
        fetchTickets();
        setShow(false);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingS(false);}
    });
  }

  return (
    <div className="support-comp">
      <Helmet>
        <title>{Seo.Support.Title}</title>
        <meta name="description" content={Seo.Support.Desc} />
        <meta name="keywords" content={Seo.Support.Keywords} />
      </Helmet>

      <div className="support-header">
        <h1>Support</h1>
        <Button status={(user)?"add":"disabled"} type="button" onClick={() => {setShow(true)}}>Open New Ticket</Button>
      </div>
      {(user)?null:
      <div className='please-log-in'>
        <div>Please Login to open a ticket. If you're having trouble logging in try <a href="/reset-password">Resetting Your Password.</a></div>
        <div>If you've lost access to your email. Please email us here: {maskedEmail}. </div>
        <div>Please Note, if you are unable to prove it is your account we will not be able to restore access.</div>
      </div>
      }
      <div className="support-tickets">
        <Loading show={loadingF} />
        {(!loadingF && tickets.length === 0)?
        <div className="no-tickets">
          No Tickets. Open a new ticket if you need help with something. 
        </div>
        :null}
        {tickets.map((v,i) => {
          let dateValue = "";
          if(v.updatedDate){
            dateValue = DateUtil.prettifyDateWithTime(v.updatedDate);
          }else{
            dateValue = DateUtil.prettifyDateWithTime(v.createdDate);
          }
          return (
            <Link to={"/ticket/"+v.id} className={"ticket "+((v.needsReply)?"gray":"")} key={i}>
              <div className="ticket-header">
                <div>Topic: {StringUtil.capitalize(v.type)}</div>
                <div>{StringUtil.capitalize(v.status)}</div>
              </div>
              <div className="ticket-content">
                <div>{v.lastMessage}</div>
                <div className="date">{dateValue}</div>
              </div>
            </Link>
          )
        })}


      </div>

      <Modal className="support-modal" show={show} handleClose={() => {setShow(false)}}>
        <div className="support-modal-container">
          <h2>Open New Ticket</h2>
          <form onSubmit={handleSubmit}>
            <div className="block">
              <label>Topic</label>
              <select disabled={loadingS} value={selectValue} onChange={(e) => setSelectValue(e.target.value)}>
                {options.map((v, i) => <option key={i} value={v.value}>{v.display}</option>)}
              </select>
            </div>
            <div className="block">
              <label>Message</label>
              <TextareaAutosize disabled={loadingS} value={textValue} onChange={(e) => setTextValue(e.target.value)} />
            </div>
            <Button status={(loadingS)?"loading":""} type="submit" value="Open Ticket" />
          </form>
        </div>
      </Modal>
       

    </div>
  );

};

export default Support;