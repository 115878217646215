import React from 'react';
import './blog.scss';
import Table from '../../elements/table/table';
import Config from '../../../config/config';
import * as DateUtil from '../../../utils/date';
import { Link } from 'react-router-dom';

const Blog = () => {


  const sideItems = [
    {display: "Published", conditions:[{
      column:"published", table:"articles", action: "=",value: 1,
    }]},
  ];
  const articleColumns = [
    {table: "articles",value: "id", index:true, hide:true},
    {table: "articles",value: "uid", index:true, hide:true},
    {table: "articles",value: "title", ignoreRowClick:true,
      custom: ({v,cv}) => {
        let author = v["articlesAuthor"];
        let pubDate = v["articlesPublishedDate"];
        let imageUrl = v["articlesImageUrl"];
        let imageText = v["articlesImageText"];
        let urlPath = v["articlesUrlPath"];
        let uid = v["articlesUid"];
        let metaDesc = v["articlesMetaDesc"];
        let title = v[cv];
        imageUrl = imageUrl.replace("{{cdn}}",Config.Common.CdnUrl);
        imageUrl = imageUrl.replace(".png","-small.png");
        return(
          <Link to={"/article/"+uid+"/"+urlPath} className='blog-article-item'>
            <div className='blog-article-item-top'>
              <div className='image-item'>
                <img src={imageUrl} alt={imageText} />              
              </div>
              <div className='title-item'>
                <h2>{title}</h2>
                <p>{metaDesc}</p>
              </div>
            </div>
            <div className='author-details'>
              <div>Author: {author}</div>
              <div>Date: {DateUtil.dateTimeSince(pubDate)}</div>
            </div>
          </Link>
        )
      }
    },
    {table: "articles",value: "metaDesc", hide:true },
    {table: "articles",value: "metaKeywords", hide:true },
    {table: "articles",value: "author", hide:true },
    {table: "articles",value: "imageUrl", hide:true },
    {table: "articles",value: "imageText", hide:true },
    {table: "articles",value: "urlPath", hide:true },
    {table: "articles",value: "published", hide:true, index:true,},
    {table: "articles",value: "author", hide:true },
    {table: "articles",display:"Pub Date",value: "publishedDate", type:"date", hide:true, index:true, defaultOrder:"DESC" },
  ]

  return (
    <div className="blog-comp">
      <h1>{Config.Common.AppName} Blog</h1>
      <Table 
        displayName="Articles"
        searchEndpoint={"/blog/search"}
        name="ArticlesTable"
        database={"Search"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={[]}
        rowOnClickFunc={(value) => {
          // setRowValue(value);
          // setEditModalShow(true);
        }}
        columns={articleColumns}
        limit={50}
        useSearchBar={false}
        useHeaderRow={false}
        />
    </div>
  );

};

export default Blog;