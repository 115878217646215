
export const splitImage = (imageSrc, aspectRatio, callback, num) => {
  aspectRatio = aspectRatio?aspectRatio:"1:1";
  const image = new Image();
  image.crossOrigin = "anonymous";
  image.onload = function() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let width;
    let height;

    switch(aspectRatio) {
        case '4:5': width = image.width / 2; height = (5/4) * width; break;
        case '2:3': width = image.width / 2; height = (3/2) * width; break;
        case '4:7': width = image.width / 2; height = (7/4) * width; break;
        case '5:4': width = image.width / 2; height = (4/5) * width; break;
        case '3:2': width = image.width / 2; height = (2/3) * width; break;
        case '7:4': width = image.width / 2; height = (4/7) * width; break;
        case '1:1': width = image.width / 2; height = width; break;
        default: console.error('Unsupported aspect ratio'); return;
    }

    const images = [];

    let startX = 0;
    let startY = 0;
    if(num){
      if(num === 1){
        startX = 0; startY = 0;
      }else if(num === 2){
        startX = 1; startY = 0;
      }else if(num === 3){
        startX = 0; startY = 1;
      }else if(num === 4){
        startX = 1; startY = 1;
      }
    }

    dance:for (let x = startX; x < 2; x++) {
      console.log("run x");
        for (let y = startY; y < 2; y++) {
          console.log("run y");
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(
                image,
                x * width, y * height,
                width, height, 
                0, 0, width, height
            );
            const dataUrl = canvas.toDataURL();
            images.push(dataUrl);
            if(num){
              break dance;
            }
        }
    }

    callback(images);
  };

  image.src = imageSrc;
}

export async function convertImageToBase64(url) {
  try {
      // Fetch the image
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const blob = await response.blob();

      // Use FileReader to convert the blob to base64
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
      });
  } catch (error) {
      console.error('Failed to convert image to base64:', error);
      return null;
  }
}