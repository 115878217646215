import React from 'react';
import './blocked-country.scss';
import { useMaskEmail } from '../../../utils/use-mask-email';
import Config from '../../../config/config';
import CenterDiv from '../../common/center-div/center-div';

const BlockedCountry = () => {

  let {maskedEmail} = useMaskEmail(Config.Common.SupportEmailName,Config.Common.SupportEmailDomain)

  let countries = [
    // "United States of America",
    // "Canada",
  ]

  return (
    <CenterDiv>
      <div className="blocked-country">
        <h2>Unsupported Country</h2>
        <p>We currently can't offer Services to users from the following countries</p>
        <ul>
          {countries.map((v,i) => <li>{v}</li>)}
        </ul>
        <p>We're actively working on ways to expand our services to the above countries. </p>
        <p>You can contact us at {maskedEmail} if you're having issues. </p>
        <p>If you'd like to test features you can use our sandbox version <a href={Config.Common.SandboxFrontEndUrl}>Sandbox Env</a></p>
      </div>
    </CenterDiv>
  );

};

export default BlockedCountry;