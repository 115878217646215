import React, { useState,useEffect } from 'react';
import './ticket.scss';
import Button from '../../elements/button/button';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import Loading from '../../common/loading/loading';
import * as DateUtil from '../../../utils/date';
import TextareaAutosize from 'react-textarea-autosize';

const Ticket = ({uid,}) => {

  const [loadingF,setLoadingF] = useState(false);
  const [loadingS,setLoadingS] = useState(false);

  const [textValue,setTextValue] = useState("");
  const [ticket,setTicket] = useState(null);
  const [ticketMessages,setTicketMessages] = useState([]);

  useEffect(() => {
    fetchTicket(uid);
    return () => {
      unSubRequest("tickets");
      unSubRequest("open-ticket");
    }
  },[uid]);

  const handleSubmit = (e) => {
    e.preventDefault();
    send();
  } 

  const fetchTicket = (uid) => {
    setLoadingF(true);
    request("ticket","/ticket/"+uid,"GET", {}, {
      then: function(res){ 
        setTicket(res.data.res.ticket);
        setTicketMessages(res.data.res.ticketMessages);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingF(false);}
    });
  }

  const send = () => {
    if(textValue === "") return;

    let data = { 
      side: "user",
      text: textValue, 
      uid: uid,
    }
    
    // console.log("data",data);

    setLoadingS(true);
    request("reply-ticket","/reply-ticket","POST", data, {
      then: function(res){
        setTextValue("");
        fetchTicket(uid);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingS(false);}
    });
  }

  return (
    <div className="ticket-comp">
      <h1>Support</h1>
      <h6>Ticket UID: {uid}</h6>
      <div className="ticket-messages">
        <Loading show={loadingF} />
        {ticketMessages.map((v,i) => {
          let dateValue = DateUtil.dateTimeSinceWithTime(v.createdDate);
          return( 
            <div key={i} className={"ticket-message "+((v.staff)?"staff":"user")}>
              <div className="ticket-user">
                <div>{(v.staff)?"Staff":"You"}</div>
              </div>
              <TextareaAutosize disabled={true} className="ticket-text" defaultValue={v.text} />
              <div className="ticket-date">
                <div>{dateValue}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="ticket-reply">
        <form onSubmit={handleSubmit}>
          <TextareaAutosize placeholder="Reply" 
            disabled={loadingS || (ticket && ticket.status === "closed")} 
            value={textValue} onChange={(e) => setTextValue(e.target.value)} />
          <Button 
            disabled={(ticket && ticket.status === "closed")}
            status={(loadingS)?"loading":""} type="submit" value="Reply" />
        </form>
      </div>       

    </div>
  );

};

export default Ticket;