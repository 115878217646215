
const AccountStatusEnum = {
  Any: 0,
  EmailUnverified: 1,
  Banned: 2,
  Disabled: 3,
  Basic: 4,
  Premium: 5,
  Pro: 6,
  Elite: 7,
  Staff: 8,
  Admin: 9,
  SuperAdmin: 10,
  System: 11,
}

export const AccountStatusIdEnum = {
  0:"Any",
  1:"EmailUnverified",
  2:"Banned",
  3:"Disabled",
  4:"Basic",
  5:"Premium",
  6:"Pro",
  7:"Elite",
  8:"Staff",
  9:"Admin",
  10:"SuperAdmin",
  11:"System",
}

export default AccountStatusEnum;