import React,{useState, } from 'react';
import './base64.scss';
import ReactTextareaAutosize from 'react-textarea-autosize';

const Base64 = () => {

  const [b64,setB64] = useState("");

  return (
    <div className="base64-comp">
      <h3>Base64 Component</h3>
      <label className='hlo video-upload button'>
        Upload File
        <input type="file"  onChange={(e) => {
          if(e.target.files.length){
            let file = e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              let base64 = reader.result;
              setB64(base64)
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
          }
        }} />
      </label>
      <ReactTextareaAutosize value={b64} readOnly>
      </ReactTextareaAutosize>

    </div>
  );
};

export default Base64;