import React from 'react';
import './side-item.scss';
import * as StringUtil from '../../../utils/string';

const SideItem = ({value,active,onClickFunc}) => {

  return (
    <div className="side-item" onClick={() => {
      if(active){
        onClickFunc();
      }
    }}>
      <div>{StringUtil.prettifyCamelCase(value)}</div>
    </div>
  );

};

export default SideItem;