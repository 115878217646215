import React,{useState,useEffect} from 'react';
import './help.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';
import { Link } from 'react-router-dom';
import {translations} from '../../../translations/all';


const Help = () => {

  // Set from the /translations/views/faq path
  const [faq,setFaq] = useState([]);
  const lang = localStorage.getItem("language") || "en";

  const selfServiceItems = [
    {display:"Reset Password",link:"/reset-password",icon:""},
    // {display:"Unfreeze/Freeze Account",link:"/my-account#freeze-account",icon:""},
    {display:"Change Email",link:"/my-account?action=change-email",icon:""},
    {display:"Change Password",link:"/my-account?action=change-password",icon:""},
    {display:"Reset 2FA",link:"/totp-reset",icon:""},
    {display:"Report A Bug",link:"/bug-report",icon:""},
    {display:"Leave Feedback",link:"/feedback",icon:""},
  ];

  useEffect(() => {
    let newFaq = [];
    if(translations && translations[lang] && translations[lang].faq){
        newFaq = translations[lang].faq;
    }
    setFaq(newFaq);
  },[])

  return (
    <div className="help-comp">
      <Helmet>
        <title>{Seo.Help.Title}</title>
        <meta name="description" content={Seo.Help.Desc} />
        <meta name="keywords" content={Seo.Help.Keywords} />
      </Helmet>
      <h1>Help Center</h1>
      <div className="block self-service">
        <h2>Self-Service</h2>
        <div className="items">
          {selfServiceItems.map((v,i) => {
            return(
              <Link className="item" to={v.link} key={i}>
                <div className="icon">{v.icon}</div>
                <div className="text">{v.display}</div>
              </Link>
            )
          })}
        </div>
      </div>
      <div className="block faq">
        <h2>FAQ</h2>
        <div className="items">
          {faq.map((v,i) => {
            return(
              <div className="item" key={i}>
                <div className="question">Q) {v.question}</div>
                <div className="answer">A) {v.answer}</div>
              </div>
            )
          })}
        </div>
      </div>
      {/* <div className="block">
        <h2>Annoucements</h2>

      </div> */}
      <div className="block">
        <h2>Still need help?</h2>
        <Link to="/contact">Contact Us</Link>
      </div>
    </div>
  );

};

export default Help;