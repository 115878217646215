import React,{useState,useEffect} from 'react';
import './dashboard.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {request,unSubRequest} from '../../../utils/request';
import { toast } from 'react-toastify';
import Table from '../../elements/table/table';
import { clone } from '../../../utils/clone';
import Modal from '../../common/modal/modal';
import ConfirmModal from '../../common/modal/confirm-modal';
import usePersistState from '../../../utils/use-persist-state';
import Checkbox from '../../elements/checkbox/checkbox';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import useUserState from '../../../store/user';
import {KlineIntervalEnum} from '../../../enums/kline-interval';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import {splitImage,convertImageToBase64} from '../../../utils/split-image';
import CopyInput from '../../elements/copy-input/copy-input';
import {ReactComponent as DownloadSvg} from '../../../css/imgs/icon-download.svg';
import SideItem from './side-item';
import Config from '../../../config/config';


let initVariationPrompt = "Please keep them similar but differ slightly, and possibly have different features.";

let initGenerationPrompt = "Please add more detail to my prompt";


const Dashboard = () => {

  const {user} = useUserState();
  const [slide,setSlide] = useState(1);
  const [imgSlide,setImgSlide] = useState(1);

  const [deleteMultiRow,setDeleteMultiRow] = useState(false);
  const [deleteMultiRowUids,setDeleteMultiRowUids] = useState([]);
  const [showConfirmMultiRowDelete,setShowConfirmMultiRowDelete] = useState(false);
  const [promptChatGptMsg,setPromptChatGptMsg] = usePersistState(initVariationPrompt,"dashUserPrompt");
  const [promptChatGptGenerationMsg,setPromptChatGptGenerationMsg] = usePersistState(initGenerationPrompt,"dashUserGenerationPrompt");
  const [newPrompts,setNewPrompts] = useState([]);
  const [variationPrompt,setVariationPrompt] = useState("");
  const [variationAspectRatio,setVariationAspectRatio] = useState("1:1");
  const [showConfirm,setShowConfirm] = useState(false);
  const [showConfirmUid,setShowConfirmUid] = useState(null);
  const [show,setShow] = useState(false);
  const [loading,setLoading] = useState(false);
  const [prompt,setPrompt] = useState("");
  const [aspectRatio,setAspectRatio] = useState("1:1");
  const [refreshQueueInc,setRefreshQueueInc] = useState(1);
  const [refreshResultsInc,setRefreshResultsInc] = useState(1);
  const [refreshClientsInc,setRefreshClientsInc] = useState(1);
  const [editUid,setEditUid] = useState(null);
  const [editPrompt,setEditPrompt] = useState("");
  const [editAspectRatio,setEditAspectRatio] = useState("");
  const [editRepeat,setEditRepeat] = useState("");
  const [showUids,setShowUids] = useState([]);
  const [status,setStatus] = useState("Idle");
  const [loadingActiveClients,setLoadingActiveClients] = useState(false);
  const [showImgModal,setShowImgModal] = useState(false);
  const [showImage,setShowImage] = useState(null);
  const [generationModal,setGenerationModal] = useState(false);
  const [showEditFolder,setShowEditFolder] = useState(false);
  const [showEditFolderVal,setShowEditFolderVal] = useState(null);
  const [showConfirmDelFolder,setShowConfirmDelFolder] = useState(false)
  const [promptQueueResults,setPromptQueueResults] = useState([]);
  const [folders,setFolders] = useState([]);

  let addFolderObj = {
    customClick:true, custom:({active,value}) => {
      return(
        <Button parentClassName={"add-button"} status="add" onClick={() => {
          setShowEditFolder(true);
          setShowEditFolderVal(null);
        }}>Add Folder</Button>
      )
    }
  }
  const [sideItemsQueuePrompts,setSideItemsQueuePrompts] = useState([
    {display: "All", conditions:[]},addFolderObj
  ]);
  const [sideItemsPromptResults,setSideItemsPromptResults] = useState([
    {display: "All", conditions:[]},addFolderObj
  ]);
  // const [sideItemsQueuePrompts,setSideItemsQueuePrompts] = usePersistState([
  //   {display: "All", conditions:[]},addFolderObj
  // ],"sideItemsQueuePrompts","object");
  // const [sideItemsPromptResults,setSideItemsPromptResults] = usePersistState([
  //   {display: "All", conditions:[]},addFolderObj
  // ],"sideItemsPromptResults","object");

  useEffect(() => {
    fetchQueueStatus();
    fetchFolders();
    return () => {
      unSubRequest("fetchQueueStatus");
      unSubRequest("add-prompts");
      unSubRequest("edit-prompt");
      unSubRequest("delete-prompts");
      unSubRequest("move-prompt");
      unSubRequest("gen-variations");
    }
  },[])

  const submitHandler = (e) => {
    e.preventDefault();
    let prompts = [{
      prompt:prompt,
      aspectRatio:aspectRatio
    }]
    addToQueue(prompts);
  }

  const fetchQueueStatus = () => {
    let endpoint = "/queue-status";
    setLoading(true);
    request("fetchQueueStatus",endpoint,"GET", {}, {
      then: function(res){
        let newStatus = res.data.res.active === true?"Running":"Idle";
        setStatus(newStatus);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const addToQueue = (prompts) => {
    let endpoint = "/prompts";
    let body = {prompts}
    setLoading(true);
    request("add-prompts",endpoint,"POST", body, {
      then: function(res){
        setRefreshQueueInc(refreshQueueInc + 1);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const editPromptRequest = (uid,prompt,aspectRatio,repeat) => {
    let endpoint = "/prompts";
    let body = { uid,prompt,aspectRatio,repeat }
    setLoading(true);
    request("edit-prompt",endpoint,"PATCH", body, {
      then: function(res){
        setEditUid(null);
        setRefreshQueueInc(refreshQueueInc + 1);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const deleteFromQueue = (uids) => {
    let endpoint = "/prompts";
    let body = {uids}
    request("delete-prompts",endpoint,"DELETE", body, {
      then: function(res){
        setRefreshQueueInc(refreshQueueInc + 1);
        setDeleteMultiRowUids([]);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }
  
  const moveUpInQueue = (uid) => {moveInQueue(uid,"up")}
  const moveDownInQueue = (uid) => {moveInQueue(uid,"down")}
  const moveInQueue = (uid,upOrDown) => {
    upOrDown = upOrDown?upOrDown:"up";
    let endpoint = "/prompts-move";
    let body = {uid,move:upOrDown}
    setLoading(true);
    request("move-prompt",endpoint,"POST", body, {
      then: function(res){setRefreshQueueInc(refreshQueueInc + 1);},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const generateVariations = (userPrompt,prompt) => {
    let endpoint = generationModal?"/gen-better-prompts":"/gen-variations";
    let body = {userPrompt,prompt}
    setLoading(true);
    request("gen-variations",endpoint,"POST", body, {
      then: function(res){
        let message = res.data.res.message;
        let newerPrompts = [];
        try{
          let json = JSON.parse(message);
          newerPrompts = json.prompts;
        }catch(e){
          console.log("e",e);
          toast.error("Error unpacking response JSON");
        }
        setNewPrompts(newerPrompts);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const startQueue = () => {startStop("start")}
  const stopQueue = () => {startStop("stop")}
  const startStop = (startOrStop) => {
    let endpoint = startOrStop === "start"?"/start-queue":"/stop-queue";
    let newStatus = startOrStop === "start"?"Running":"Idle";
    setLoading(true);
    request("start-stop",endpoint,"POST", {}, {
      then: function(res){setStatus(newStatus)},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const toggleActiveClient = (uid) => {
    setLoadingActiveClients(true);
    request("start-stop","/toggle-active-client","POST", {uid}, {
      then: function(res){setRefreshClientsInc(refreshClientsInc+1);},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoadingActiveClients(false)}
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveFolder();
  }

  const fetchFolders = () => {
    setLoading(true);
    request("folder-fetch","/folder","GET", {}, {
      then: function(res){
        let results = res.data.res;
        let newQitems = [{display: "All", conditions:[]}];
        let newRitems = [{display: "All", conditions:[]}];
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          const name = result.name?result.name:"null-name";
          newQitems.push({
            display: name, conditions:[{column: "folderUid", table: "queuedPrompts",action: "=",value: result.uid,}],
            custom:({active,value}) => {<SideItem active={active} value={name} onClickFunc={() => {
              setShowEditFolder(true);
              setShowEditFolderVal(result);
            }} />},
          });
          newRitems.push({
            display: name, conditions:[{column: "folderUid", table: "promptResults",action: "=",value: result.uid,}],
            custom:({active,value}) => <SideItem active={active} value={name} onClickFunc={() => {
              setShowEditFolder(true);
              setShowEditFolderVal(result);
            }} />,
          });
        }
        newQitems.push(addFolderObj);
        newRitems.push(addFolderObj);
        setSideItemsQueuePrompts(newQitems);
        setSideItemsPromptResults(newRitems);
        let newFolders = [{uid:"none",name:"None"}];
        for (let i = 0; i < results.length; i++) {
          newFolders.push(results[i]);
        }
        setFolders(newFolders);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const saveFolder = () => {
    setLoading(true);
    request("folder-save","/folder","POST", showEditFolderVal, {
      then: function(res){fetchFolders();setShowEditFolder(false)},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const deleteFolder = () => {
    setLoading(true);
    request("folder-del","/folder","DELETE", showEditFolderVal, {
      then: function(res){fetchFolders();setShowEditFolder(false)},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const moveFolderUp = () => {moveFolder("up")}
  const moveFolderDown = () => {moveFolder("down")}
  const moveFolder = (move) => {
    let body = {
      uid: showEditFolderVal.uid,
      upOrDown: move,
    }
    setLoading(true);
    request("folder-move","/folder-move","POST", body, {
      then: function(res){fetchFolders()},
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false)}
    });
  }

  const saveItemFolder = (itemUid,folderUid) => {
    let body = {itemUid,folderUid}
    console.log("body",body);
    request("save-folder-item","/save-folder-item","POST", body, {
      then: function(res){},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  const queuedPromptsColumns = [
    {table: "queuedPrompts",value: "uid", index:true, hide:true,},
    {table: "queuedPrompts",value: "aspectRatio", hide:true,},
    {table: "queuedPrompts",value: "prompt", rowOnClickFunc:false,
      custom: ({v,cv}) => {
        let uid = v["queuedPromptsUid"];
        let edit = uid === editUid?true:false;
        let aspectRatio = v["queuedPromptsAspectRatio"];
        let repeat = v["queuedPromptsRepeat"];
        let prompt = v[cv];
        let deleteMultiRowSelected = deleteMultiRowUids.indexOf(uid) !== -1?true:false;
        let deleteMultiRowClass = deleteMultiRow?"delete-multi-row-on":""
        let deleteRowClass = deleteMultiRow && deleteMultiRowSelected?"delete-row-selected":"";
        let showHideButtons = (showUids.indexOf(uid) !== -1) && !deleteMultiRow?"expand":"collapse";

        if(edit){
          aspectRatio = editAspectRatio;
          repeat = editRepeat;
          prompt = editPrompt;
        }

        return(
          <div className={"queued-prompt "+deleteRowClass+" "+deleteMultiRowClass}>
            
            <div className='content'>
              <select disabled={!edit} value={aspectRatio} onChange={(e) => {setEditAspectRatio(e.target.value)}} >
                <option value="1:1">AR 1:1</option>
                <option value="4:5">AR 4:5</option>
                <option value="2:3">AR 2:3</option>
                <option value="4:7">AR 4:7</option>
                <option value="5:4">AR 5:4</option>
                <option value="3:2">AR 3:2</option>
                <option value="7:4">AR 7:4</option>
              </select>
              <Textarea value={prompt} onChange={(e) => {setEditPrompt(e.target.value)}} disabled={!edit}/>      
              <div className='repeat'>
                <label>Repeat</label>
                <input disabled={!edit} type="text" value={repeat} onChange={(e) => {setEditRepeat(e.target.value)}} />
              </div>

              <Button status={"more"} disabled={deleteMultiRow} onClick={() => {
                let cloneShowUids = clone(showUids);
                let index = showUids.indexOf(uid);
                if(index !== -1){
                  cloneShowUids.splice(index,1);
                }else{
                  cloneShowUids.push(uid);
                }
                setShowUids(cloneShowUids);
              }}>More</Button>

              <div className="checkbox-scale">
                <Checkbox
                  inLabel={false}
                  value={deleteMultiRowUids.indexOf(uid) !== -1?true:false}
                  setValue={() => {
                    let index = deleteMultiRowUids.indexOf(uid);
                    if(index === -1){
                      let cloneUids = clone(deleteMultiRowUids);
                      cloneUids.push(uid);
                      setDeleteMultiRowUids(cloneUids);
                    }else{
                      let cloneUids = clone(deleteMultiRowUids);
                      cloneUids.splice(index,1);
                      setDeleteMultiRowUids(cloneUids);
                    }
                  }}
                  />
              </div>
              

            </div>
            <div className={'button-container '+showHideButtons}>
              <div className='buttons left'>
                <Button status={loading?"loading":"up"} onClick={() => {
                  moveUpInQueue(uid);
                }}>Up</Button>
                <Button status={loading?"loading":"down"} onClick={() => {
                  moveDownInQueue(uid);
                }}>Down</Button>
              </div>  

              <div className='buttons right'>
                {(edit)?
                <>
                  <Button parentClassName={"cancel"} status="close" onClick={() => {
                    setEditUid(null);
                  }}>Cancel</Button>
                  <Button parentClassName={"save"} status={loading?"loading":"save"} onClick={() => {
                    editPromptRequest(uid,prompt,aspectRatio,repeat);
                  }}>Save</Button>
                </>
                :
                <>
                  
                  <Button status="shuffle" onClick={() => {
                    setShow(true);
                    setVariationPrompt(prompt);
                    setGenerationModal(false);
                  }}>Shuffle</Button>
                  <Button status="copy" onClick={() => {
                    let prompts = [{
                      aspectRatio,
                      prompt,
                      repeat,
                    }]
                    addToQueue(prompts);
                  }}>Clone</Button>
                  <Button status="edit" disabled={editUid !== null} onClick={() => {
                    setEditUid(uid);
                    setEditPrompt(prompt)
                    setEditAspectRatio(aspectRatio)
                    setEditRepeat(repeat)
                  }}>Edit</Button>
                  <Button status={loading?"loading":"delete"} onClick={() => {
                    setShowConfirm(true);
                    setShowConfirmUid(uid);
                  }}>Delete</Button>
                </>
                }
              </div>  
            </div>
          </div>
      )},
    },
    {table: "queuedPrompts",value: "json", hide:true,},
    {table: "queuedPrompts",value: "status", hide:true,},
    {table: "queuedPrompts",value: "repeat", hide:true,},
    {table: "queuedPrompts",value: "createdDate", type:"date",hide:true,},
    {table: "queuedPrompts",value: "orderDate", index:true, type:"date", defaultOrder:"DESC",hide:true,},
    {table: "queuedPrompts",value: "updatedDate", type:"date", hide:true,},
  ];

  
  const queuePromptsJoinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  const promptResultsColumns = [
    {table: "promptResults",value: "uid", index:true, hide:true, },
    {table: "promptResults",value: "prompt", hide:true, },
    {table: "promptResults",value: "json", hide:true, },
    {table: "promptResults",value: "url", ignoreRowClick:true,display:"Image",
      customDisplay: () => {
        return (
          <div className='image-refresh'>
            <Button status={"refresh"}
              onClick={() => {setRefreshResultsInc(refreshResultsInc + 1)}}
            >Refresh</Button>
            <div className='text'>
              Image
            </div>
          </div>
        )
      }, 
      custom: ({v,cv}) => {
        // console.log("v",v);
        // let uid = v['promptResultsUid'];
        let imgSrc = v[cv];
        imgSrc = imgSrc.replace("{{cdn}}",Config.Common.CdnUrl);
        let aspectRatio = v['promptResultsAspectRatio'];

        let width = 120 * 2;
        let height;
    
        switch(aspectRatio) {
            case '4:5': height = (5/4) * width; break;
            case '2:3': height = (3/2) * width; break;
            case '4:7': height = (7/4) * width; break;
            case '5:4': height = (4/5) * width; break;
            case '3:2': height = (2/3) * width; break;
            case '7:4': height = (4/7) * width; break;
            case '1:1': height = width; break;
            default: console.error('Unsupported aspect ratio'); return;
        }

        let style2 = {maxHeight: height,maxWidth: width,}
        let style = {maxHeight: height/2,maxWidth: width/2,}

        return (
          <div className='result-image-div-container'>
            <div id={"result-image-uid"} className='result-image-div' >
              <div style={style} className="image-item"><img style={style} src={imgSrc} /><span className='hlo'><DownloadSvg onClick={() => {downloadImage(v,0)}}/></span></div>
              <div style={style} className="image-item zoom-image top-left"><img style={style2} src={imgSrc} /><span className='hlo'><DownloadSvg onClick={() => {downloadImage(v,1)}}/></span></div>
              <div style={style} className="image-item zoom-image top-right"><img style={style2} src={imgSrc} /><span className='hlo'><DownloadSvg onClick={() => {downloadImage(v,2)}}/></span></div>
              <div style={style} className="image-item zoom-image bottom-left"><img style={style2} src={imgSrc} /><span className='hlo'><DownloadSvg onClick={() => {downloadImage(v,3)}}/></span></div>
              <div style={style} className="image-item zoom-image bottom-right"><img style={style2} src={imgSrc} /><span className='hlo'><DownloadSvg onClick={() => {downloadImage(v,4)}}/></span></div>
            </div>
            <CopyInput display={"src"} value={imgSrc}/>
          </div>
          
        )
      }
    },
    {table: "promptResults",value: "aspectRatio", },
    {table: "promptResults",value: "folderUid", hide:true },
    {table: "promptResults",value: "createdDate",display:"Date", index:true, type:"date", defaultOrder:"DESC",},
    {table: "promptResults",value: "createdDate",display:"Actions", ignore:true, ignoreRowClick:true,
      custom: ({v,cv}) => {
        let uid = v['promptResultsUid'];
        let folderUid = v['promptResultsFolderUid'];
        return (
          <div className='button-actions'>
            <form>
              <select value={folderUid?folderUid:""} onChange={(e) => {
                folderUid = e.target.value;
                saveItemFolder(uid,folderUid,);
              }}>
                {folders.map((v2,i) => {
                  return(
                    <option value={v2.uid} key={i}>{v2.name?v2.name:"null-name"}</option>
                  )
                })}
              </select>
            </form>
            {/* <Button status="view">
              View
            </Button> */}
          </div>
        )
      }
    },

  ];

  

  const promptResultsJoinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]


  const activeClientsColumns = [
    {table: "activeClients",value: "uid", index:true, hide:true,},
    {table: "activeClients",value: "use", 
      custom: ({v,cv}) => {
        return(
          <div className='switch-toggle-box'>
            <SwitchToggle  
              loading={loadingActiveClients}
              isOn={v[cv]}
              textOn={"On"}
              textOff={"Off"}
              onClickFunc={() => {
                let uid = v['activeClientsUid'];
                toggleActiveClient(uid);
              }}
              />
          </div>
        )
      }
    },
    {table: "activeClients",value: "clientName", index:true},
    {table: "activeClients",value: "status", },
    
    {table: "activeClients",value: "lastUpdateDate", index:true, type:"date", defaultOrder:"DESC",},
    {table: "activeClients",value: "createdDate", index:true, type:"date", hide:true,},
  ];

  const sideItemsClients = [];
  sideItemsClients.push({
    display: "Default",
    conditions:[{
      action: "=",
      column: "userUid", 
      table: "activeClients",
      value: user.uid,
    },{
      action: ">=",
      column: "lastUpdateDate", 
      table: "activeClients",
      value: new Date(new Date().getTime() - KlineIntervalEnum.MinuteFive),
    }]
  });


  let aspectRatioClass = "aspect-ratio-1-1";
  if(showImage){
    let aspectRatio = showImage.promptResultsAspectRatio;
    switch(aspectRatio) {
      case '4:5': aspectRatioClass = "aspect-ratio-4-5"; break;
      case '2:3': aspectRatioClass = "aspect-ratio-2-3"; break;
      case '4:7': aspectRatioClass = "aspect-ratio-4-7"; break;
      case '5:4': aspectRatioClass = "aspect-ratio-5-4"; break;
      case '3:2': aspectRatioClass = "aspect-ratio-3-2"; break;
      case '7:4': aspectRatioClass = "aspect-ratio-7-4"; break;
      case '1:1': aspectRatioClass = "aspect-ratio-1-1"; break;
    }
  }

  const downloadImage = async (obj,num) => {
    // console.log("downloadImage")
    // console.log("num",num);
    let uid = obj['promptResultsUid'];
    let url = obj['promptResultsUrl'];
    let aspectRatio = obj['promptResultsAspectRatio'];
    // console.log("obj",obj);
    // console.log("url",url);
    // console.log("aspectRatio",aspectRatio);

    if(num === 0){
      let base64 = await convertImageToBase64(url);
      downloadBase64Image(base64,uid+"-"+num);  
    }else{
      splitImage(url, aspectRatio, (images) => {
        let base64 = images[0];
        downloadBase64Image(base64,uid+"-"+num);    
      }, num);
    }
  }

  function downloadBase64Image(base64, filename = 'downloaded_image.png') {
    const anchor = document.createElement('a');
    anchor.href = base64;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  return (
    <div className="dashboard-comp">
      <Helmet>
        <title>{Seo.Dash.Title}</title>
        <meta name="description" content={Seo.Dash.Desc} />
        <meta name="keywords" content={Seo.Dash.Keywords} />
      </Helmet>

      <h1>Dashboard</h1>

      <div className='box top-box'>
        <div className='top-header'>
          <h4>Active Clients</h4>
          <h4>Status: {status}</h4>
        </div>
        <Table 
          displayName="activeClients"
          name="activeClientsTable"
          sideItems={sideItemsClients}
          sideItemActiveClickFunc={(item) => {
            // console.log("item",item);
          }}
          joinOptions={[]}
          rowOnClickFunc={(value) => {
            // setShow(true);
            // setShowValue(value);
          }}
          refreshInc={refreshClientsInc}
          useSearchBar={false}
          disableRowHighlight={true}
          columns={activeClientsColumns}
          limit={20}
          disableIndexSort={true}
          bottomLeftButtons={[
            { text:"Refresh", status:"refresh", onClick:() => {setRefreshClientsInc(refreshClientsInc + 1)}, }
          ]}
          bottomRightButtons={[
            { status:(loading?"loading":"stop"),text:"Stop",onClick:() => {stopQueue();}, },
            { status:(loading?"loading":"play"),text:"Start",onClick:() => {startQueue();}, }
          ]}
        />
      </div>
      <div className='dash-content'>
        <div className='folders'></div>
        <div className='carousel-block'>
          <CarouselHeader className="dash-carousel-header" slideTo={slide}>
            <div onClick={() => {setSlide(1)}}>Results</div>
            <div onClick={() => {setSlide(2)}}>Prompt Queue</div>
          </CarouselHeader>
          <Carousel slideTo={slide}>
            <div>
              <div className='box results'>
                <Table 
                  displayName="PromptResults"
                  name="PromptResultsTable"
                  searchEndpoint={"/dash-search"}
                  sideItems={sideItemsPromptResults}
                  sideItemActiveClickFunc={(item) => {
                    // console.log("item",item);
                  }}
                  joinOptions={promptResultsJoinOptions}
                  rowOnClickFunc={(value) => {
                    setShowImgModal(true);
                    setShowImage(value)
                    // console.log("value",value);
                  }}
                  refreshInc={refreshResultsInc}
                  useSearchBar={false}
                  columns={promptResultsColumns}
                  limit={20}
                  bottomLeftButtons={[
                    { text:"Refresh", status:"refresh", onClick:() => {setRefreshResultsInc(refreshResultsInc + 1)}, }
                  ]}
                  bottomRightButtons={[
                    { text:"Download All", status:"download", onClick:() => {
                      toast.info("coming soon");
                      // setRefreshResultsInc(refreshResultsInc + 1)
                    }, }
                  ]}
                  />
              </div>
            </div>
            <div>
              <div className='box prompt-queue'>
                <form className='add-prompt-form' onSubmit={submitHandler}>
                  <div className='inputs'>
                    <select value={aspectRatio} onChange={(e) => {setAspectRatio(e.target.value)}}>
                      <option value="1:1">AR 1:1</option>
                      <option value="4:5">AR 4:5</option>
                      <option value="2:3">AR 2:3</option>
                      <option value="4:7">AR 4:7</option>
                      <option value="5:4">AR 5:4</option>
                      <option value="3:2">AR 3:2</option>
                      <option value="7:4">AR 7:4</option>
                    </select>
                    <Textarea placeholder='Prompt' value={prompt} onChange={(e) => {setPrompt(e.target.value)}} />
                  </div>
                  <div className='buttons'>
                    <Button type="submit" status={loading?"loading":"add"} value="Add Prompt"/>
                    <Button parentClassName="generator-button" type="button" status={loading?"loading":"robot" } onClick={() => {
                      setShow(true);
                      setVariationPrompt(prompt);
                      setGenerationModal(true);
                    }}>
                      Generator
                    </Button>
                  </div>
                  
                </form>
                <div className="prompt-queue-list">
                  {(deleteMultiRow)?
                  <div className='delete-multi-buttons'>
                    
                    <Button parentClassName={"save delete-multi-button delete"} status={loading?"loading":"delete"} onClick={() => {
                      setShowConfirmMultiRowDelete(true);
                    }}>Delete Selected Rows</Button>
                    <Button parentClassName={"cancel delete-multi-button"} status="close" onClick={() => {
                      setDeleteMultiRow(false);
                    }}>Cancel</Button>
                    <Button parentClassName={"delete-multi-button"} status="select-all" onClick={() => {
                      let newDeleteMultiRowUids = [];
                      if(promptQueueResults.length !== deleteMultiRowUids.length){
                        for (let i = 0; i < promptQueueResults.length; i++) {
                          const {queuedPromptsUid} = promptQueueResults[i];
                          newDeleteMultiRowUids.push(queuedPromptsUid);
                        }
                      }
                      setDeleteMultiRowUids(newDeleteMultiRowUids);
                    }}>{promptQueueResults.length !== deleteMultiRowUids.length?"Select All Rows":"Deselect All Rows"}</Button>
                    
                  </div>
                  :
                  <div className='delete-multi-buttons'>
                    <Button parentClassName={"delete-multi-button"} status="delete" onClick={() => {
                      setDeleteMultiRow(true);
                    }}>Delete Multi Row</Button>
                  </div>
                  }
                  <Table 
                      displayName="QueuedPrompts"
                      name="QueuedPromptsTable"
                      searchEndpoint={"/dash-search"}
                      sideItems={[]}
                      // sideItems={sideItemsQueuePrompts}
                      sideItemActiveClickFunc={(item) => {
                        // console.log("item",item);
                      }}
                      joinOptions={queuePromptsJoinOptions}
                      rowOnClickFunc={(value) => {
                        // console.log("value",value);
                      }}
                      refreshInc={refreshQueueInc}
                      useSearchBar={false}
                      columns={queuedPromptsColumns}
                      limit={20}
                      disableRowHighlight={true}
                      useHeaderRow={false}
                      returnResultsFunc={(results) => {
                        setPromptQueueResults(results);
                      }}
                      bottomLeftButtons={[
                        { text:"Refresh", status:"refresh", onClick:() => {setRefreshQueueInc(refreshQueueInc + 1)}, }
                      ]}
                    />
                </div>
              </div>
            </div>
          
          </Carousel>
        </div>
      </div>

      

      
      <Modal className={"variation-modal"} handleClose={() => {setShow(false);}} show={show}>
        <div>
          <div className='variation-top'>
            <h4>{generationModal?"Generation Modal":"Variation Modal"}</h4>
            {generationModal?
            <p>Ask ChatGPT to improve and add details to this prompt</p>
            :
            <p>Ask ChatGPT to make multiple variations of this prompt</p>
            }
            <label>ChatGpt Instructions Prompt</label>
            <Textarea 
              placeholder={generationModal?initGenerationPrompt:initVariationPrompt} 
              value={generationModal?promptChatGptGenerationMsg:promptChatGptMsg} 
              onChange={(e)=>{
                let newVarMsg = promptChatGptMsg
                let newGenMsg = promptChatGptGenerationMsg;
                if(generationModal){
                  newGenMsg = e.target.value;
                }else{
                  newVarMsg = e.target.value;
                }
                setPromptChatGptGenerationMsg(newGenMsg);
                setPromptChatGptMsg(newVarMsg)
              }}></Textarea>
            <label>{generationModal?"Generation":"Variation"} Prompt</label>
            <Textarea value={variationPrompt} onChange={(e)=>{setVariationPrompt(e.target.value)}}></Textarea>
            <select value={variationAspectRatio} onChange={(e) => {setVariationAspectRatio(e.target.value)}}>
              <option value="1:1">Aspect Ratio 1:1</option>
              <option value="4:5">Aspect Ratio 4:5</option>
              <option value="2:3">Aspect Ratio 2:3</option>
              <option value="4:7">Aspect Ratio 4:7</option>
              <option value="5:4">Aspect Ratio 5:4</option>
              <option value="3:2">Aspect Ratio 3:2</option>
              <option value="7:4">Aspect Ratio 7:4</option>
            </select>
            <Button status={loading?"loading":"forward"} onClick={() => {
              generateVariations(promptChatGptMsg,variationPrompt);
            }}>Generate</Button>
          </div>
          <div className='variation-bottom'>
            <h4>Results</h4>
            <div className='button-container'>
              <Button status={loading?"loading":"add"} onClick={() => {
                let localPrompts = [];
                for (let i = 0; i < newPrompts.length; i++) {
                  let obj = {
                    prompt:newPrompts[i],
                    aspectRatio:variationAspectRatio
                  }
                  localPrompts.push(obj);
                }
                addToQueue(localPrompts);
                setNewPrompts([]);
              }}>Add All</Button>
            </div>
            <ul>
              {newPrompts.map((v,i) => {
                return(
                  <li  key={i}>
                    <div className="text">{v}</div>
                    <Button status={loading?"loading":"add"} onClick={() => {
                      let cloneNewPrompts = clone(newPrompts);
                      cloneNewPrompts.splice(i,1);
                      let prompts = [{
                        prompt:v,
                        aspectRatio:variationAspectRatio
                      }]
                      addToQueue(prompts);
                      setNewPrompts(cloneNewPrompts);
                    }}>Add</Button>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Modal>

      <ConfirmModal show={showConfirm} handleClose={(confirmed) => {
          if(confirmed){
            deleteFromQueue([showConfirmUid]);
          }
          setShowConfirm(false);
          setShowConfirmUid(null);
        }} 
        >
        <div>
          Are you sure you want to delete this prompt?
        </div>
      </ConfirmModal>

      <ConfirmModal show={showConfirmMultiRowDelete} handleClose={(confirmed) => {
          if(confirmed){
            deleteFromQueue(deleteMultiRowUids);
          }
          setShowConfirmMultiRowDelete(false);
        }} 
        >
        <div>
          Are you sure you want to delete these rows?
        </div>
      </ConfirmModal>


      <Modal className={"image-modal"} show={showImgModal} handleClose={() => {setShowImgModal(false)}}>
        <div>
          {showImage?
            <div>
              <CarouselHeader className={"image-modal-header"} slideTo={imgSlide}>
                <div className="header-slide" onClick={() => {setImgSlide(1)}}><div className={'aspect-ratio '+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className="header-slide" onClick={() => {setImgSlide(2)}}><div className={'aspect-ratio zoom top-left '+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className="header-slide" onClick={() => {setImgSlide(3)}}><div className={'aspect-ratio zoom top-right '+aspectRatioClass} ><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className="header-slide" onClick={() => {setImgSlide(4)}}><div className={'aspect-ratio zoom bottom-left '+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className="header-slide" onClick={() => {setImgSlide(5)}}><div className={'aspect-ratio zoom bottom-right '+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className="header-slide" onClick={() => {setImgSlide(6)}}>Info</div>
              </CarouselHeader>
              <Carousel slideTo={imgSlide} setSlide={setImgSlide} sideSlider={true} slideCount={6} sideBarPadding={12}>
                <div className='slide'><div className={"aspect-ratio "+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className='slide'><div className={"aspect-ratio zoom top-left "+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className='slide'><div className={"aspect-ratio zoom top-right "+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className='slide'><div className={"aspect-ratio zoom bottom-left "+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className='slide'><div className={"aspect-ratio zoom bottom-right "+aspectRatioClass}><img src={showImage?showImage.promptResultsUrl.replace("{{cdn}}",Config.Common.CdnUrl):""} /></div></div>
                <div className='slide'>
                  <div className='info-slide'>
                    <div>
                      <label>Prompt</label>
                      <CopyInput value={showImage.promptResultsPrompt} textarea={true}/>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          :null}
          {/* <div className='buttons'>
            <div>
              <div onClick={() => {
                let newSlide = imgSlide - 1;
                if(newSlide <= 1){newSlide = 1;}
                setImgSlide(newSlide)}
              }>Prev</div>
            </div>
            <div>
              <div onClick={() => {
                let newSlide = imgSlide + 1;
                if(newSlide >= 5){newSlide = 5;}
                setImgSlide(newSlide)}
              }>Next</div>
            </div>
          </div> */}
        </div>
      </Modal>

      <Modal show={showEditFolder} handleClose={() => {setShowEditFolder(false)}}>
        <div>
          <h4>Edit/Add Folder Modal</h4>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Name</label>
              <input value={showEditFolderVal && showEditFolderVal.name?showEditFolderVal.name:""} type="text" placeholder='Name' onChange={(e) => {
                let newVal = showEditFolderVal;
                if(newVal === null){
                  newVal = { name: e.target.value }
                }else{
                  newVal = clone(showEditFolderVal);
                  newVal.name = e.target.value
                }
                setShowEditFolderVal(newVal);
              }} />
            </div>
            {showEditFolderVal && showEditFolderVal.uid?
              <div className='buttons-folder-modal move-up-down'>
                <Button type="button" status={loading?"loading":"up"} onClick={() =>{
                  moveFolderUp();
                }}>Move Up</Button>
                <Button type="button" status={loading?"loading":"down"} onClick={() =>{
                  moveFolderDown();
                }}>Move Down</Button>
              </div>
            :null}
            
            <div className='buttons-folder-modal'>
              {/* <Button parentClassName={"clear"} type="button" onClick={() =>{setShowEditFolder(false)}}>Cancel</Button> */}
              <Button parentClassName={"delete"} type="button" status={loading?"loading":"delete"} onClick={() =>{
                setShowConfirmDelFolder(true);
              }}>Delete</Button>
              <Button type="submit" status={loading?"loading":"save"} value="Save" />
            </div>
          </form>
        </div>
      </Modal>

      <ConfirmModal show={showConfirmDelFolder} handleClose={(confirmed) => {
          if(confirmed){
            deleteFolder();
          }
          setShowConfirmDelFolder(false);
        }} 
        >
        <div>
          Are you sure you want to delete this folder?
        </div>
      </ConfirmModal>
    </div>
  );

};

export default Dashboard;