import React, { useState,useEffect } from 'react';
import './payment-details.scss';

import {request,unSubRequest} from '../../../utils/request';
import Loading from '../../common/loading/loading';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import Payment from '../../common/payment/payment';
import { toast } from 'react-toastify';
import {capitalize} from '../../../utils/string';

const PaymentDetails = () => {

  const [paymentMethods,setPaymentMethods] = useState([]);
  const [defaultPaymentMethodId,setDefaultPaymentMethodId] = useState(null);
  const [loading,setLoading] = useState(false);
  const [newDefaultId, setNewDefaultId] = useState(null);
  const [removingId,setRemovingId] = useState(null);
  const [defaultLoading,setDefaultLoading] = useState(false);
  const [removeLoading,setRemoveLoading] = useState(false);

  const [showModal,setShowModal] = useState(false);

  useEffect(() => {
    return () => {
      unSubRequest("stripe-payment-method");
      unSubRequest("stripe-payment-method-set-default");
      unSubRequest("stripe-payment-method-remove");
    }
  },[]);

  useEffect(() => {
    fetchPaymentDetails();
  },[]);

  const fetchPaymentDetails = () => {
    setLoading(true);
    request("stripe-payment-method","/stripe-payment-method","GET", {}, {
      then: function(res){
        setPaymentMethods(res.data.res.paymentMethods.data);
        setDefaultPaymentMethodId(res.data.res.defaultPaymentMethodId);
      },
      catch: function(err){
        toast.error(err.message);
      },
      finally: function(){
        setLoading(false);
      }
    });
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const successCallback = () => {
    fetchPaymentDetails();
    handleClose();
  }

  const makeDefault = (id) => {
    setNewDefaultId(id);
    setDefaultLoading(true);
    const endpoint = "/stripe-payment-method/"+id+"/set-default";
    request("stripe-payment-method-set-default",endpoint,"POST", {}, {
      then: function(res){
        setDefaultPaymentMethodId(id);
      },
      catch: function(err){
        toast.error(err.message);
      },
      finally: function(){
        setDefaultLoading(false);
      }
    });
  }

  const removePaymentMethod = (id,paymentMethods) => {
    setRemovingId(id);
    setRemoveLoading(true);
    const endpoint = "/stripe-payment-method/"+id+"/remove";
    request("stripe-payment-method-remove",endpoint,"POST", {}, {
      then: function(res){
        let defaultId = defaultPaymentMethodId;
        if(res.data.res.setNewDefaultId && res.data.res.newDefaultId !== null){
          defaultId = res.data.res.newDefaultId;
        }
        let clonePaymentMethods = paymentMethods.filter((value) => {
          return (value.id !== id)
        });
        setPaymentMethods(clonePaymentMethods);
        setDefaultPaymentMethodId(defaultId);
      },
      catch: function(err){
        toast.error(err.message);
      },
      finally: function(){
        setRemoveLoading(false);
      }
    });
  }

  return (
    <div className="payment-details">
      <h3>Payment Details</h3>
      <Loading show={loading} size="24px"/>
      <ul className="payment-methods-list">
        {paymentMethods.map((value,index) => {

          let defaultPaymentMethod = false;
          if(value.id === defaultPaymentMethodId){
            defaultPaymentMethod = true
          }

          return (
            <li key={index}>
              <div className="card-info">
                <div className="card-name">{value.billingDetails.name}</div>
                <div className="card-digits">
                  <div>{capitalize(value.card.brand)} •••• •••• •••• {value.card.last4}</div>
                </div>
                <div className="card-expiration">
                  <div>Expires: {value.card.expMonth}/{value.card.expYear}</div>
                </div>
              </div>
              <div className="card-options">
                <Button 
                  disabled={defaultLoading || defaultPaymentMethod || loading}
                  parentClassName={"default-button"+((defaultPaymentMethod)?" default":" clear")} 
                  type="button" 
                  status={(defaultLoading && value.id === newDefaultId) ? "loading" :""} 
                  onClick={() => {makeDefault(value.id)}}
                  >
                  {(defaultPaymentMethod) ? "Default" : "Set Default"}
                </Button>
                <Button 
                  disabled={removeLoading || loading}
                  parentClassName={"clear delete"}
                  type="button" 
                  status={(removeLoading && value.id === removingId) ? "loading" : "delete"}
                  onClick={() => {removePaymentMethod(value.id,paymentMethods)}}
                  >
                  Remove
                </Button>
              </div>
            </li>
          )
        })}

        {(paymentMethods.length >= 1 || loading)?"":
          <li className="no-payment-methods">Add a payment method to easily make purchases or maintain subscriptions. </li>
        }
      </ul>
      <Button type="button" status="add" onClick={() => {setShowModal(true)}}>Add Payment Method</Button>
      <Modal className="add-payment-method-modal" show={showModal} handleClose={handleClose}>
        <div>
          <h3>Add Payment Method</h3>
          <Payment successCallback={successCallback} />
        </div>
      </Modal>
    </div>
  );

};

export default PaymentDetails;