import React,{useState,useEffect} from 'react';
import './my-account.scss';

import AccountType from './account-type';
// import Notifications from './notifications';
import PaymentDetails from './payment-details';
import DangerZone from './danger-zone';
import ProfileDetails from './profile-details';
import LinkSnsAccounts from './link-sns-accounts';
// import AccountSecurity from './account-security';
// import MyCourses from './my-courses';
// import PublicProfile from './public-profile';
// import Affiliate from './affiliate';
import Api from '../api/api';
import * as BrowserUtil from '../../../utils/browser';


const MyAccount = () => {

  const action = BrowserUtil.getQueryVariable("action");

  const [hash,setHash] = useState("");

  useEffect(() => {
    let newHash = "";
    if (window.location.hash) {
      newHash = window.location.hash
      newHash = newHash.replace('#', '');
    }
    setHash(newHash);
  }, []);

  useEffect(() => {
    if (hash !== "") {
      try{
        const element = document.getElementById(hash);
        if (element) {
          const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 60; // 60px padding
          window.scrollTo({ top: offsetTop, behavior: 'smooth' });
          // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }catch(e){}
    }
  }, [hash]);

  return (
    <div className="my-account">
      <h1>My Account</h1>

      {/* <PublicProfile/> */}
      {/* <MyCourses /> */}
      <ProfileDetails action={action}/>
      <LinkSnsAccounts />
      <AccountType />
      <PaymentDetails />
      <Api load={true}/>
      {/* <AccountSecurity/> */}
      {/* <Affiliate/> */}
      {/* <Notifications /> */}
      <DangerZone />

    </div>
  );

};

export default MyAccount;