import React, { useEffect,useState } from 'react';
import './how-to-use.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';
import Config from '../../../config/config';
import { Link } from 'react-router-dom';

let links = [
  {title:"Getting Started",id:"getting-started"},
  {title:"Generate Prompts",id:"generate-prompts"},
  {title:"Generate Prompt Variations",id:"generate-prompt-variations"},
  {title:"Set Up Api",id:"set-up-api"},
]

const HowToUse = () => {

  const [hash,setHash] = useState("");

  useEffect(() => {
    let newHash = "";
    if (window.location.hash) {
      newHash = window.location.hash
      newHash = newHash.replace('#', '');
    }
    setHash(newHash);
  }, []);

  useEffect(() => {
    if (hash !== "") {
      try{
        const element = document.getElementById(hash);
        if (element) {
          const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 60; // 60px padding
          window.scrollTo({ top: offsetTop, behavior: 'smooth' });
          // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }catch(e){}
    }
  }, [hash]);

  return (
    <div className="how-to-use-comp">

      <Helmet>
        <title>{Seo.HowToUse.Title}</title>
        <meta name="description" content={Seo.HowToUse.Desc} />
        <meta name="keywords" content={Seo.HowToUse.Keywords} />
      </Helmet>

      <div className='header-div'>
        <h1>How to use {Config.Common.AppName}</h1>
        <p>Firstly, {Config.Common.AppName} is free to use and easy to set up. Here are the steps you'll need to take to get started.</p>
        <p>If you get lost or need help, we're available via our <Link to="/help">Help</Link> page.</p>
      </div>
      <div className='content-div'>
        <div className='guide'>
          {links.map((v,i) => {
            let activeClass = v.id === hash?"active":"";
            return (
              <Link className={activeClass} key={i} to={"/how-to-use#"+v.id} onClick={() => {setHash(v.id)}}>{v.title}</Link>
            )
          })}
        </div>
        <div className='blocks'>
          <div className='block' id="getting-started">
            <h2>Getting Started</h2>
            <div className='video'>
              <iframe src="https://www.youtube.com/embed/6ZHgvTixJNE?si=Sd-6cnINLHQ4Qxn6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <ol>
              <li>Add the Fjorney extension & make a Fjorney account</li>
              <li>Paste in your Midjourney discord URL into the Fjorney extension</li>
              <li>Add your pompts and hit start</li>
            </ol>
            <p className='note'>If you get lost anywhere, please watch the youtibe video. If you're still having trouble you can open a ticket with us on our <Link to="/help">help page</Link></p>
          </div>
          <div className='block' id="generate-prompts">
            <h2>Generate Prompts</h2>
            <div className='video'>
              <iframe src="https://www.youtube.com/embed/jC8g61ovJME?si=1EBn_gTjc65fgnSJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className='block' id="generate-prompt-variations">
            <h2>Generate Prompt Variations</h2>
            <div className='video'>
              <iframe src="https://www.youtube.com/embed/-5AOcKuGjVg?si=q9fhBAkbNigfiLtU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className='block' id="set-up-api">
            <h2>Set Up Api</h2>
            <div className='video'>
              <iframe src="https://www.youtube.com/embed/Y4vkPmvKnnM?si=SlilrISgfFkJGwgw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <ol>
              <li>Make sure you've completed the "Getting Started" section.</li>
              <li>Create an api-key on your My Account page..</li>
              <li>Visit our <a target="_blank" href="https://docs.fjorney.com">Api Docs</a> and copy our example for nodejs. Replace the keys with your keys.</li>
            </ol>
            <p className='note'>You need to leave a computer with the Fjorney extension installed open along with the browser open aswell.</p>
            <p className='note'>Currently only 1 client is supported but multiple will be supported soon.</p>
            <p className='note'>If you get lost anywhere, please watch the youtibe video. If you're still having trouble you can open a ticket with us on our <Link to="/help">help page</Link></p>
          </div>
        </div>
      </div>
    </div>
  );

};

export default HowToUse;