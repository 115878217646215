import React,{useState,useEffect} from 'react';
import './api.scss';
import Config from '../../../config/config';
import {Environment, SandboxEnvArr} from '../../../config/env';
import * as StringUtil from '../../../utils/string';
import {request,unSubRequest} from '../../../utils/request';
import { toast } from 'react-toastify';
import Button from '../../elements/button/button';
import Modal from '../../common/modal/modal';
import Loading from '../../common/loading/loading';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import ApiKeyItem from './api-key-item';
import TextareaAutosize from 'react-textarea-autosize';
import useUserState from '../../../store/user';
import {ReactComponent as TotpSvg} from '../../../css/imgs/icon-totp.svg';
import {Link} from 'react-router-dom';
import CopyInput from '../../elements/copy-input/copy-input';

const Api = ({type,uid,load}) => {

  const requireTotp = false;

  const {user} = useUserState();

  const appName = StringUtil.capitalize(Config.Common.AppName);
  const [loading,setLoading] = useState(false);
  const [apikeys,setApikeys] = useState([]);
  const [show,setShow] = useState(false);
  const [modalValue,setModalValue] = useState(null);
  const [enabled,setEnabled] = useState(true);
  const [display,setDisplay] = useState("");
  const [limitIp,setLimitIp] = useState(true);
  const [ipAddresses,setIpAddresses] = useState("");
  const [totp,setTotp] = useState("");
  const [newApiKeyResults,setNewApiKeyResults] = useState(null);

  const [didAppear,setDidAppear] = useState(false);

  useEffect(() => {
    if(load !== undefined && load === true && didAppear === false){
      setDidAppear(true);
      fetch(type,uid);
    }
  },[type,uid,load,didAppear])

  useEffect(() => {
    return () => {
      unSubRequest("fetch-api-key");
    }
  },[])

  const fetch = (type,uid) => {
    let endpoint = "/api-key";
    if(type && type === "merchant"){
      endpoint = "/merchant-api-key/"+uid;
    }
    setLoading(true);
    request("fetch-api-key",endpoint,"GET", {}, {
      then: function(res){ 
        setApikeys(res.data.res);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const handleClose = () => {
    setNewApiKeyResults(null);
    setShow(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    send(type,uid);
  }

  const send = (type,uid) => {

    let method = "POST";
    let body = {
      totp: totp,
      limitIp: (limitIp)?1:0,
      enabled: (enabled)?1:0,
      name: display,
      ipAddresses: ipAddresses,
    }
    
    if(modalValue){
      body.key = modalValue;
      method = "PATCH";
    }

    let endpoint = "/api-key";
    if(type && type === "merchant"){
      endpoint = "/merchant-api-key";
      body.storeUid = uid;
    }

    setLoading(true);
    request("send-api-key",endpoint,method, body, {
      then: function(res){
        let newNewApiKeyResults = null;
        let newShow = false;
        if(modalValue === null){
          newShow = true;
          newNewApiKeyResults = res.data.res;
        }
        setShow(newShow);
        setNewApiKeyResults(newNewApiKeyResults);
        fetch(type,uid);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <div className="api-comp" id="api-keys">
      <h3>{(type === "merchant")?"Merchant Api Keys":"Api Keys"}</h3>
      {(type === "merchant")?
      <p></p>:
      <p>Interact with {appName} programatically using an Api Key.</p>
      }
      <p>Read the <a href="https://docs.fjorney.com">docs</a> to learn more about how to use this api key. Or check the <Link to="/how-to-use">How To Use</Link> page to see examples.</p>
      <p className="note">Never give your Api Key to anyone. These should be considered as sensitive as your login information. </p>

      <div className="api-keys">
        <Loading show={loading} size="24px"/>
        {(apikeys.length <= 0)?
        <div className="no-api-keys">No Api Keys</div>
        :null}
        {apikeys.map((v,i) => 
          <ApiKeyItem 
            storeUid={uid}
            type={type}
            disableFunc={() => {fetch(type,uid)}}
            deleteFunc={() => {fetch(type,uid)}}
            editFunc={() => {
              setModalValue(v.key);
              setEnabled(v.enabled?true:false);
              setDisplay(v.name);
              setLimitIp(v.limitIp);
              setIpAddresses(v.ipAddresses);
              setShow(true);
              setTotp("");
            }} 
            key={i} 
            apiKey={v} />
        )}
      </div>
            
      <Button status={"add"} type="button" onClick={() => {
        setModalValue(null);
        setEnabled(true);
        setDisplay("");
        setLimitIp(true);
        setIpAddresses("");
        setTotp("");
        setShow(true);
      }}>Create New Api Key</Button>

      <Modal className="modify-api-key-modal-main" show={show} handleClose={handleClose}>
        <div className="modify-api-key-modal">
        {
          (newApiKeyResults)?
          <div className="new-api-ley-results">
            <h3>New Api Key Details</h3>
            <p>This is the only time the secret will ever be displayed. Please create a new key if you lose it.</p>
            <CopyInput display="Name" value={newApiKeyResults.name} />
            <CopyInput display="Key" value={newApiKeyResults.key} />
            <CopyInput display="Secret" value={newApiKeyResults.secret} />
          </div>
          :(!user.totpSet && SandboxEnvArr.indexOf(Environment) === -1 && requireTotp)?
          <div className="totp-div">
            <div className="icon"><TotpSvg/></div>
            <div className="text">
              <div className="header">Set Up 2FA Security</div>
              <div className="sub">
                For your account security please set up 2 factor authentication before sending or receiving funds.
              </div>
              <Link className="button" to="/my-account#account-security" onClick={handleClose}>Set Up 2FA</Link>
            </div>
          </div>:
          <div className="api-key-modify-div">
            <h3>{(modalValue)?"Modify":"Create"} Api Key</h3>
            <form onSubmit={handleSubmit}>
              <div className="toggle-box">
                <div>
                  <label>Enabled</label>
                </div>
                <SwitchToggle onClickFunc={() => {setEnabled(!enabled)}} isOn={enabled} />
              </div>
              <div>
                <label>Name</label>
                <input type="text" value={display} onChange={(e) => {setDisplay(e.target.value)}} />
              </div>
              <div className="toggle-box">
                <div>
                  <label>Limit IP Address Access</label>
                </div>
                <SwitchToggle onClickFunc={() => {setLimitIp(!limitIp)}} isOn={limitIp} />
              </div>
              <div>
                <label>IP Addresses, Separate by comma</label>
                <TextareaAutosize disabled={!limitIp} type="text" value={ipAddresses} onChange={(e) => {setIpAddresses(e.target.value)}} />
                <div className="example-ip">
                  <div>Example: </div>
                  <div>123.000.000.000,456.000.000.000</div>
                </div>
              </div>
              {(user.totpSet)?
              <div>
                <label>2FA Totp Code</label>
                <input type="text" value={totp} onChange={(e) => {setTotp(e.target.value)}} />
              </div>
              :null}
              <Button type="submit" status={(loading)?"loading":"save"} value={((modalValue)?"Modify":"Create")+" Api Key"} />
            </form>
          </div>
        }
        </div>
      </Modal>
    </div>
  );

};

export default Api;