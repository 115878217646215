import React from 'react';
import Entry from './entry';
import './entry.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

const EntryCreateAccount = ({code}) => {

  

  return (
    <div className="entry-create-account">
      <Helmet>
        <title>{Seo.CreateAccount.Title}</title>
        <meta name="description" content={Seo.CreateAccount.Desc} />
        <meta name="keywords" content={Seo.CreateAccount.Keywords} />
      </Helmet>

      <Entry code={code} option="create-account"/>
    </div>
  )
};

export default EntryCreateAccount;