import React,{useState, useEffect} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
import {request, unSubRequest} from '../../../utils/request';
import Button from '../../elements/button/button';

const PaymentForm = ({formSubmitedCallback,successCallback,errorCallback,hideButton,passRef}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [nameOnCard,setNameOnCard] =  useState("");
  const [loading,setLoading] = useState(false);
  const [cardElementFocus, setCardElementFocus] = useState(false);

  useEffect(() => {
    return () => {
      unSubRequest("stripe-payment-method-attach");
    }
  },[])

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if(nameOnCard === ""){
      toast.error("Name on Card cannot be empty");
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if(formSubmitedCallback !== undefined){ formSubmitedCallback();}
    // Use your card Element with other Stripe.js APIs
    setLoading(true);
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: nameOnCard,
      },
    });

    if(error){
      toast.error(error.message);
      setLoading(false);
      if(errorCallback !== undefined){ errorCallback(error); }
      return;
    } 
    
    const endpoint = "/stripe-payment-method/"+paymentMethod.id+"/attach";
    request("stripe-payment-method-attach",endpoint,"POST", {}, {
      then: function(res){
        if(successCallback !== undefined){ successCallback(res); }
      },
      catch: function(err){
        toast.error(err.message);
        if(errorCallback !== undefined){errorCallback(err);}
      },
      finally: function(){setLoading(false);}
    });
  };
  if(passRef !== undefined){
    passRef.current = handleSubmit;
  }

  const cardElementStyle = {
    base: {
      fontSize: "16px",
    },
  }

  const cardElementOptions = {
    style: cardElementStyle,
    disabled: loading,
  }

  return (
    <form onSubmit={(e) => {e.preventDefault();handleSubmit();}}>
      <div>
        <label>Name on Card</label>
        <input 
          type="text" 
          placeholder="Name on Card" 
          value={nameOnCard} 
          disabled={loading}
          onChange={(e) => {setNameOnCard(e.target.value)}} />
      </div>
      <div>
        <label>Card Details</label>
        <CardElement 
          className={"card-element"+((cardElementFocus) ? " focus" : "") }
          options={cardElementOptions} 
          onFocus={() => {setCardElementFocus(true)}}
          onBlur={() => {setCardElementFocus(false)}}/>
      </div>
      {/* <h6>
        Your payment information is securely saved via Stripe.
        You can read more about how they handle your data via their 
        website <a 
          target="_blank" 
          href="https://stripe.com/privacy" 
          rel="noopener noreferrer">
            stripe.com/privacy
        </a>.
      </h6> */}
      <Button 
        parentClassName={(hideButton)?"hide":""}
        type="submit" 
        disabled={!stripe}
        value="Add Payment Method"
        status={(loading) ? "loading" : "save"}
        />
    </form>
  );
};

export default PaymentForm;