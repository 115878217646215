import React from 'react';
import './billing-rate.scss';

const BillingRate = ({billingRate,setBillingRate,billingRates}) => {
  return (
    <div className='billing-rate-comp hlo'>
      <label>Billing Rate</label>
      <div className='billing-rate-options'>
      {billingRates.map((v,i) => {
        let activeClass =  billingRate.month === v.month?"active":"";
        return(
          <div className={'billing-rate-option '+activeClass} key={i} onClick={() => {setBillingRate(v)}}>
            <div className='billing-rate-text'>
              {v.month} {v.month === 1?"Month":"Months"}
            </div>
            {(v.save !== 0)?
            <span className='billing-rate-save'>Save {v.save}%</span>
            :null}
          </div>
        )
      })}
      </div>
    </div>
  );

};

export default BillingRate;