import React from 'react';
import './home.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';
import {Link} from 'react-router-dom';

// import EdgePng from '../../../css/imgs/edge.png';
import {ReactComponent as ChromeSvg} from '../../../css/imgs/chrome.svg';

import {ReactComponent as AddComputerSvg} from '../../../css/imgs/home/icon-add-computer.svg';
import {ReactComponent as SignUpSvg} from '../../../css/imgs/home/icon-signup-done.svg';
import {ReactComponent as InputSvg} from '../../../css/imgs/home/icon-input.svg';

import FAQ from '../faq/faq';

// let firefoxImgUrl = "https://cdn.cdnlogo.com/logos/m/87/mozilla-firefox.svg";
let googleChromeLink = "https://chromewebstore.google.com/detail/fjorney/nemomhmkkfjabmpkbkgcfigmhfgkkjpk";
// let edgeLink = "";
// let firefoxLink = "";

const Home = () => {

  return (
    <div className="home-comp">

      <Helmet>
        <title>{Seo.Home.Title}</title>
        <meta name="description" content={Seo.Home.Desc} />
        <meta name="keywords" content={Seo.Home.Keywords} />
      </Helmet>

      <div className="section header1">
        <div className="inner boxer reverse">
          <div className="image">
            <span><img src={"/images/midjourney-bot.png"} alt="Midjourney Bot"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h1>Empower Your Journeys with Midjourney API, Midjourney Auto, and Midjourney Bot: Streamlining Excellence for Seamless Automation</h1>
            {/* <h1>Midjourney Bot</h1> */}
            <h3>Super Powers for Midjourney</h3>
            <a className="button start-gen-btn" href={googleChromeLink} target="_blank">
              <div>Start Generating Images</div>
              <span>It's free</span>
            </a>
            <div className='avail-div'>
              <div>Available on</div>
              <a target="_blank" href={googleChromeLink}><div className='icon'><ChromeSvg/></div><span>Google Chrome</span></a>
              {/* <a href={firefoxLink}><div className='image'></div><span>Firefox</span></a> */}
              {/* <a href={edgeLink}><div className='icon'><img src={EdgePng} alt="edge"/></div><span>Microsoft Edge</span></a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="section bg">
        <div className="inner boxer">
          <div className="image svg">
            <span><img src={"/images/midjourney-queue-prompt.png"} alt="Midjourney Queue Prompt"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Queue Prompts</h2>
            <p>Midjourney automated. Easily create a prompt queue which automatically pings midjourney to generates images for you. </p>
            <p>You can also organize the prompts into folders to keep your workspace clean and easy to manage.</p>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="inner boxer reverse">
          <div className="image svg">
            <span><img src={"/images/midjourney-api.png"} alt="Midjourney API"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Midjourney Api</h2>
            <p>Grab an api-key, check out our easy to understand docs and you're good to go. Check out our how-to-use-guide if you get lost. There's short videos explaing how to set it up. </p>
          </div>
        </div>
      </div>
      <div className="section bg">
        <div className="inner boxer">
          <div className="image svg">
          <span><img src="/images/midjourney-prompt-generator.png" alt="Midjourney Prompt Generator" /></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Midjourney Prompt Generator</h2>
            <p>Have an idea of what you want but don't feel like writing a lengthy description for Midjourney? Gotcha covered there too. Just type in a few details and leave the rest to us. </p>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="inner boxer reverse">
          <div className="image svg">
            <span><img  src={"/images/midjourney-prompt-variations.png"} alt="Midjourney Prompt Variations"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Midjourney Prompt Variations</h2>
            <p>Have a prompt you like and want to generate similar prompts? Boom. That's right. We have that too.</p>
          </div>
        </div>
      </div>
      <div className="section bg how-it-works-section">
        <div className="inner boxer">
          <div className="text">
            <h2>How it works</h2>
            <p>Add Fjorney as an extension to your browser. Add your Midjourney Discord URL link to the extension. Add some prompts and hit start. Done. Let Fjorney do the rest.</p>
            <p>If you want to use the api, grab an api key, leave a computer with fjorney installed and the browser open, hit our api endpoints and fjorney will automaically generate images from Midjourney.</p>
          </div>
        </div>
      </div>

      <div className="section bg steps-section">
        <div className="inner boxer">
          <div className="text">
            <h2>Steps to set up</h2>
            <h3>Takes less than a minute</h3>
            <div className='steps'>
              <div className='step one'>
                <div className='num'>1</div>
                <div className='icon'><AddComputerSvg /></div>
                <span>Add Fjorney to your browser</span>
              </div>
              <div className='step two'>
                <div className='num'>2</div>
                <div className='icon'><SignUpSvg /></div>
                <span>Create a Fjorney account</span>
              </div>
              <div className='step three'>
                <div className='num'>3</div>
                <div className='icon'><InputSvg /></div>
                <span>Add your Discord URL & Prompts</span>
              </div>
            </div>
            <div className='how-to-use-note'>Check our <Link to="/how-to-use">How To Use</Link> guide if you get stuck anywhere.</div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="inner boxer">
          <div className="image svg">
            <span><img  src={"/images/image-1.jpg"} alt="Transforming Creativity with MidJourney API"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Transforming Creativity with MidJourney's API</h2>
            <p>
              At Fjorney, we are revolutionizing the way we approach creativity and design with the MidJourney API. With its cutting-edge AI technology, users can generate stunning, high-quality images in seconds. Whether you're a developer, marketer, or content creator, the MidJourney API provides the tools you need to bring your visual ideas to life effortlessly. Embrace the future of image generation and see how the MidJourney API can transform your projects.
            </p>
          </div>
        </div>
      </div>
      <div className="section bg">
        <div className="inner boxer reverse">
          <div className="image svg">
            <span><img  src={"/images/image-2.jpg"} alt="The Future of Visual Content Creation"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>The Future of Visual Content Creation</h2>
            <p>
              As technology continues to evolve, Fjorney is at the forefront of visual content creation with the MidJourney API. By harnessing the power of AI, this API offers a glimpse into the future of design and creativity. Stay ahead of the curve and explore how the MidJourney API on fjorney.com can enhance your content strategy. From generating high-quality images to saving time and resources, the benefits are endless.            
            </p>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="inner boxer">
          <div className="image svg">
            <span><img  src={"/images/image-3.jpg"} alt="The Future of Visual Content Creation"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Customization at Your Fingertips</h2>
            <p>
              One of the standout features of the MidJourney API on Fjorney is its customization options. Users can tailor the generated images to match their specific needs, adjusting style, content, and quality to perfection. This level of control ensures that every image aligns with your brand's aesthetic and message. Explore the customization possibilities and create visuals that truly resonate with your audience.
            </p>
          </div>
        </div>
      </div>

 


      <div className="section faq">
        <div className="inner boxer">
          <div className="text">
            <FAQ />
          </div>
        </div>
      </div>

      <div className='try-it-div'>
        <Link className='button try-it' to="/">Try it, it's free</Link>
      </div>
      
    </div>
  );
};

export default Home;