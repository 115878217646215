import React from 'react';
import SnsButton from  '../../elements/sns-button/sns-button';
import {ConfigVariables} from '../../../config/config';

const LinkSnsAccounts = () => {

  return (
    <div className="link-sns-accounts">
      <h3>Link SNS Accounts</h3>
      <p>Link your social media accounts to easily log in</p>
      {ConfigVariables.SnsAuthMethods.map((v,i) => {
        return <SnsButton key={i} action={"link"} snsType={v}/>
      })}
    </div>
  );

};

export default LinkSnsAccounts;