import React from 'react';
import './welcome.scss';
import { Helmet } from "react-helmet";
import Seo from '../../../data/seo';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Welcome = () => {

  return (
    <div className="welcome-comp">
      <Helmet>
        <title>{Seo.Welcome.Title}</title>
        <meta name="description" content={Seo.Welcome.Desc} />
        <meta name="keywords" content={Seo.Welcome.Keywords} />
      </Helmet>

      <h1>Welcome</h1>
      <p>Here's a quick video showing you how to get started with Fjorney</p>
      <div className='video'>
        <iframe src="https://www.youtube.com/embed/6ZHgvTixJNE?si=Sd-6cnINLHQ4Qxn6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <ol>
        <li>Add the Fjorney extension & make a Fjorney account</li>
        <li>Paste in your Midjourney discord URL into the Fjorney extension</li>
        <li>Add your pompts and hit start</li>
      </ol>
      <p className='note'>If you get lost anywhere, please watch the youtibe video. If you're still having trouble you can open a ticket with us on our <Link to="/help">help page</Link></p>
    </div>
  );

};

export default Welcome;