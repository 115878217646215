import React from 'react';
import './careers.scss';
import Config from '../../../config/config';
import {useMaskEmail} from '../../../utils/use-mask-email';
import CenterDiv from '../../common/center-div/center-div';


const Careers = () => {

  const appName = Config.Common.AppName;
  const {maskedEmail} = useMaskEmail(Config.Common.SupportEmailName,Config.Common.SupportEmailDomain);

  return (
    <div className="careers-comp">
      <CenterDiv>
        <div>
          <h1>Careers</h1>
          <p>Thanks for your interest in {appName}</p>
          <p>Let us know why you think you might be a good addition to the team. </p>
          <p>Send us an email over to {maskedEmail}. </p>
          <p> Please include your CV and/or any relative information. </p>
        </div>
      </CenterDiv>
      
    </div>
  );
};

export default Careers;