import React,{useState,useEffect} from 'react';
import './copy-input.scss';

import {ReactComponent as CopySvg} from '../../../css/imgs/icon-copy.svg';
import {ReactComponent as CheckSvg} from '../../../css/imgs/icon-check.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTextareaAutosize from 'react-textarea-autosize';


const CopyInput = ({display,value,textarea}) => {

  const [copied,setCopied] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCopied(false);
    },1200);
    if(!copied){
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    }
  },[copied]);

  const handleFocus = (event) => event.target.select();

  return (
    <div className="copy-input">
      {(display && display !== "")?<div className="display">{display}</div>:null}
      {(textarea)?
      <ReactTextareaAutosize value={value} readOnly></ReactTextareaAutosize>
      :
      <input type="" value={value} onFocus={handleFocus} readOnly/>
      }
      <CopyToClipboard text={value} onCopy={() => {
          setCopied(true);
        }}>
        <div className="copy">
          {(copied)?<CheckSvg/>:<CopySvg/>}
        </div>
      </CopyToClipboard>
    </div>
  )
}

export default CopyInput;