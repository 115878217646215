import React,{useState} from 'react';

import DeleteAccountModal from './delete-account-modal';
import Button from '../../elements/button/button';

const DangerZone = () => {

  const [deleteAccountShow,setDeleteAccountShow] = useState(false);

  return (
    <div className="danger-zone">
      <h3>Danger Zone <span role="img" aria-label="caution">⚠️</span></h3>
      
      <div className="danger-items">
        <div className="delete-account">
          <div>
            <label>
              <h4>Delete Account </h4>
              <p>This action is unreversible. Please be certain. </p>
            </label>
            
          </div>
          <Button 
            onClick={() => {setDeleteAccountShow(true)}}
            status="delete" 
            parentClassName="delete" 
            type="button">Delete Account
          </Button>
        </div>
      </div>

      <DeleteAccountModal 
        show={deleteAccountShow}
        handleClose={() => {setDeleteAccountShow(false)}}
        />
    </div>
  );

};

export default DangerZone;