import React from 'react';
import './logo.scss';
import logoWhite from '../../../css/imgs/logo-white.png';
import logoBlue from '../../../css/imgs/logo-blue.png';
import logoBlack from '../../../css/imgs/logo-black.png';

const Logo = ({options}) => {
  options = (options)?options:"";
  let imgSrc = logoBlue;
  if(options.includes("white")){
    imgSrc = logoWhite;
  }else if(options.includes("black")){
    imgSrc = logoBlack;
  }

  return (
    <div className={"logo-c "+options}>
      <img src={imgSrc} alt="Fjorney Logo" />
      <div className='text'>
        <span className="one">Fjorney</span>
      </div>
    </div>
  );

};

export default Logo;