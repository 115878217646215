import React,{useState,useEffect} from 'react';
import './faq.scss';
import {translations} from '../../../translations/all';

const FAQ = () => {

  const lang = localStorage.getItem("language") || "en";
  const [faq,setFaq] = useState([]);

  useEffect(() => {
    let newFaq = [];
    if(translations && translations[lang] && translations[lang].faq){
        newFaq = translations[lang].faq;
    }
    setFaq(newFaq);
  },[])

  return(
    <div className='faq-comp'>
      <div className='faq-header'>
        <div className='line'></div>
        <h2>Frequently Asked Questions</h2>
        <div className='line'></div>
      </div>
      <ul className='faq-list'>
        {faq.map((v,i) => {
          return(
            <li className="item" key={i}>
              <div className='faq-text'>
                <div className="question">{v.question}</div>
                <div className="answer">{v.answer}</div>
              </div>              
            </li>
          )
        })}
      </ul>
    </div>

  )
}

export default FAQ;
