import React,{useEffect, useState,} from 'react';
import './api-key-item.scss';
import {request,unSubRequest} from '../../../utils/request';
import { toast } from 'react-toastify';
import Button from '../../elements/button/button';
import CopyInput from '../../elements/copy-input/copy-input';

const ApiKeyItem = ({type,storeUid,apiKey,editFunc,deleteFunc,disableFunc}) => {

  const [loading,setLoading] = useState(false);

  useEffect(() => {
    return () => {
      unSubRequest("api-key-delete");
      unSubRequest("api-key-disable");
    }
  },[]);

  const deleteApiKey = (key,type,storeUid) => {
    let endpoint = "/api-key";
    let body = {
      key:key,
    }
    if(type && type === "merchant"){
      endpoint = "/merchant-api-key";
      body.storeUid = storeUid;
    }
    setLoading(true);
    request("api-key-delete",endpoint,"DELETE", body, {
      then: function(res){
        if(deleteFunc){
          deleteFunc()
        }
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const disableApiKey = (key,type,storeUid) => {
    let endpoint = "/api-key-disable";
    let body = {
      key:key,
    }
    if(type && type === "merchant"){
      endpoint = "/merchant-api-key-disable";
      body.storeUid = storeUid;
    }
    console.log("body",body);
    setLoading(true);
    request("api-key-disable",endpoint,"PATCH", body, {
      then: function(res){
        if(disableFunc){
          disableFunc();
        }
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <div className="api-key-item-comp">
      <div className="details">
        <div className="one">
          <div>{apiKey.name}</div>
          <div>{(apiKey.enabled)?"Enabled":"Disabled"}</div>
        </div>
        <CopyInput display="Key" value={apiKey.key}/>
        <div className="two">
          <div>Limit IP Access: {(apiKey.limitIP)?"On":"Off"}</div>
          <div>{apiKey.ipAddresses}</div>
        </div>
      </div>
      <div className="buttons">
        <Button status={(loading)?"loading":"edit"} type="button" onClick={() => {
          if(editFunc){
            editFunc()
          }
        }}>Edit</Button>
        <Button status={(loading)?"loading":(apiKey.enabled)?"power-off":"power-on"} type="button" onClick={() => {
          if(apiKey.enabled){
            disableApiKey(apiKey.key, type, storeUid)
          }else{
            if(editFunc){
              editFunc()
            }
          }
        }}>Disable</Button>
        <Button status={(loading)?"loading":"delete"} type="button" onClick={() => {
          deleteApiKey(apiKey.key, type, storeUid)
        }}>Delete</Button>
      </div>
    </div>
  );

};

export default ApiKeyItem;