import React from 'react';
import './switch-toggle.scss';
import Loading from '../../common/loading/loading';

const SwitchToggle = ({isOn,onClickFunc,textOn,textOff,width,height,divisor,borderRadius,loading,disable}) => {
  width = width?width:72;
  height = height?height:width/2;
  divisor = divisor?divisor:6;
  borderRadius = borderRadius !== undefined?borderRadius:2500;
  let switchToggleDotHeight = height - divisor;
  let switchToggleDotWidth = ((width)/2) - divisor;

  if (loading === undefined){
    loading = false;
  }

  let style = {
    height: height,
    width: width,
    minWidth: width,
    borderRadius: borderRadius,
  }

  let lineStyle = {
   left: (switchToggleDotHeight / 2) + (divisor / 2) - 1, // side
    // left: (width / 4) + (divisor / 2),// center
  }

  let circleStyle = {
    height: switchToggleDotHeight,
    width: switchToggleDotHeight,
    top: (height - switchToggleDotHeight) / 2,
    left: (width - switchToggleDotHeight) - (divisor / 2), // side
  }

  let dotStyle = {
    top: (height - switchToggleDotHeight) / 2,
    left: (height - switchToggleDotHeight) / 2,
    height: switchToggleDotHeight,
    width: switchToggleDotWidth,
    borderRadius: borderRadius,
  };
  if(isOn){
    dotStyle.left = ((width/2)) + (divisor/2);
  }

  return (  
    <div 
      style={style}
      className={"switch-toggle hlo"+((isOn)?" on":" off")+((disable)?" disable":"")} 
      onClick={() => {
        if(disable || loading) return;

        onClickFunc();
      }}>
      <div className="line" style={lineStyle}></div>
      <div className="circle" style={circleStyle}></div>
      <div className="dot" style={dotStyle}>
        {(textOn && textOff)?
          <div className='text'>
            <div className='text-on'>{textOn}</div>
            <div className='text-off'>{textOff}</div>
          </div>:null}
        {loading?
        <div className={'dot-loading-div'}>
          <Loading show={loading} size={height/2}/>
        </div>
        :null}
      </div>
      <div className='gray-cover'></div>
    </div>
  );

};

export default SwitchToggle;